import { cn } from "utils/cn";

export const TableWrapper = ({
  shadow = false,
  children,
  legend
}: {
  children: React.ReactNode;
  shadow?: boolean;
  legend?: string;
}) => (
  <div className="flex flex-col">
    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        <div
          className={cn(
            shadow && "shadow",
            "overflow-hidden border-b border-gray-200 sm:rounded-lg"
          )}
        >
          {children}
        </div>
        {legend && (
          <legend className="py-2 text-sm text-gray-500">{legend}</legend>
        )}
      </div>
    </div>
  </div>
);
