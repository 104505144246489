import type {
  UseFieldApiConfig,
  UseFieldApiProps
} from "@data-driven-forms/react-form-renderer/use-field-api";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import { RadioGroup } from "@headlessui/react";
import { HelpText } from "components/form/HelpText";
import { kebabCase } from "lodash";
import type { SelectOption } from "types/form";
import type { FormOption } from "types/model/form";
import { cn } from "utils/cn";

interface RadioGroupWithDescriptionProps
  extends UseFieldApiProps<string, HTMLElement> {
  isDisabled?: boolean;
  isSubFormSubmitted?: boolean;
  options?: SelectOption[];
}

export const RadioGroupWithDescription = (props: UseFieldApiConfig) => {
  const {
    label,
    index,
    helpText,
    input,
    options,
    isSubFormSubmitted,
    isDisabled,
    showLabel = false,
    meta: { error, touched }
  }: RadioGroupWithDescriptionProps = useFieldApi(props);

  const touchedOrSubFormSubmitted = touched || isSubFormSubmitted;
  const isFirstItem = index === 0;

  return (
    <RadioGroup
      value={input.value}
      onChange={input.onChange}
      className={cn(!isFirstItem && "mt-7")}
    >
      <RadioGroup.Label
        className={cn(
          showLabel &&
            "block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2",
          !showLabel && "sr-only"
        )}
      >
        {label}
      </RadioGroup.Label>
      <div
        className={cn("-space-y-px rounded-md bg-white", showLabel && "mt-1")}
      >
        {options?.map(
          (
            option: FormOption & {
              description?: string;
            },
            index: number
          ) => (
            <RadioGroup.Option
              key={option.value}
              value={option.value}
              disabled={isDisabled}
              className={({ checked }) =>
                cn(
                  index === 0 ? "rounded-tl-md rounded-tr-md" : "",
                  index === options.length - 1
                    ? "rounded-bl-md rounded-br-md"
                    : "",
                  checked
                    ? "z-10 border-indigo-200 bg-indigo-50"
                    : "border-gray-200",
                  touchedOrSubFormSubmitted && error && "border-red-300",
                  !isDisabled && "cursor-pointer",
                  "relative flex border p-4 focus:outline-none"
                )
              }
              data-element={`ticket-type-option-${kebabCase(option.label)}`}
              data-cy={`radiogroup-option-${kebabCase(option.label)}`}
            >
              {({ active, checked }) => (
                <>
                  <span
                    className={cn(
                      checked
                        ? "border-transparent bg-indigo-600"
                        : "border-gray-300 bg-white",
                      active ? "ring-2 ring-indigo-500 ring-offset-2" : "",
                      "mt-0.5 flex h-4 w-4 cursor-pointer items-center justify-center rounded-full border"
                    )}
                    style={{ minWidth: "1rem" }}
                    aria-hidden="true"
                  >
                    <span className="h-1.5 w-1.5 rounded-full bg-white" />
                  </span>
                  <div className="ml-3 flex flex-col">
                    <RadioGroup.Label
                      as="span"
                      className={cn(
                        checked ? "text-indigo-900" : "text-gray-900",
                        "block text-sm font-medium"
                      )}
                    >
                      {option.label}
                    </RadioGroup.Label>
                    <RadioGroup.Description
                      as="span"
                      className={cn(
                        checked ? "text-indigo-700" : "text-gray-500",
                        "block text-sm"
                      )}
                    >
                      {option.description}
                    </RadioGroup.Description>
                  </div>
                </>
              )}
            </RadioGroup.Option>
          )
        )}
      </div>
      {helpText && <HelpText label={label} helpText={helpText} />}
      {touchedOrSubFormSubmitted && error && (
        <p className="mt-2 text-sm text-red-600">{error}</p>
      )}
    </RadioGroup>
  );
};
