import type { Schema } from "@data-driven-forms/react-form-renderer";
import componentTypes from "@data-driven-forms/react-form-renderer/component-types";
import dataTypes from "@data-driven-forms/react-form-renderer/data-types";
import { getCurrencyAmountForDisplay } from "helpers/currency";
import { FormTemplateType } from "types/form";
import type {
  ActivityGroup,
  ActivityGroupFullListItem
} from "types/model/activity-group";
import type { Client } from "types/model/client";
import type { Field as FieldModel } from "types/model/field";
import type { PaymentMethodInstance } from "types/model/payment";
import type {
  SessionPass,
  SessionPassPurchaseOption,
  SessionPassTransactionWithCreditUsage
} from "types/model/session-pass";
import { PassCreditUsageOption } from "types/model/session-pass";
import type { Venue } from "types/model/venue";

interface GenerateSessionPassFormSchemaData {
  sessionPass?: SessionPass<ActivityGroup[], PaymentMethodInstance[]>;
  activityGroups?: ActivityGroupFullListItem[];
  venues?: Venue[];
  activityFields?: FieldModel[];
  enabledPaymentMethodInstances?: PaymentMethodInstance[];
  client: Client;
  formTemplate?: FormTemplateType;
}

export const generateSessionPassFormSchema = ({
  sessionPass,
  activityGroups,
  venues,
  activityFields,
  enabledPaymentMethodInstances,
  client,
  formTemplate = FormTemplateType.Default
}: GenerateSessionPassFormSchemaData): Schema => {
  const getPassCreditUsageOptionInitialValue = (
    sessionPass: SessionPass<ActivityGroup[], PaymentMethodInstance[]>
  ) => {
    if (sessionPass?.canBeUsedForAllActivityGroups) {
      return PassCreditUsageOption.AllActivityGroups;
    } else if (sessionPass?.canBeUsedForAllActivityGroups === false) {
      return PassCreditUsageOption.SelectedActivityGroups;
    }
    return null;
  };

  const fields = [
    {
      index: 0,
      arrayField: false,
      component: componentTypes.TEXT_FIELD,
      name: "name",
      label: "Name",
      isRequired: true,
      validate: [{ type: "required" }],
      formTemplate,
      initialValue: sessionPass?.name
    },
    {
      index: 1,
      arrayField: false,
      component: "radio-group-with-description-plain",
      inputType: "number",
      name: "passCreditUsageOption",
      label: "Pass credit usage",
      isRequired: true,
      validate: [{ type: "required" }],
      options: [
        {
          value: PassCreditUsageOption.AllActivityGroups,
          label: "All activities",
          description:
            "If selected, pass credits can be used for sessions in any activities."
        },
        {
          value: PassCreditUsageOption.SelectedActivityGroups,
          label: "Selected activities",
          description:
            "If selected, pass credits can only be used for sessions in selected activities."
        }
      ],
      formTemplate,
      initialValue: getPassCreditUsageOptionInitialValue(
        sessionPass as SessionPass<ActivityGroup[], PaymentMethodInstance[]>
      )
    },
    {
      index: 2,
      arrayField: false,
      component: "activity-groups",
      name: "activityGroups",
      label: "Activities pass credits can be used for",
      id: sessionPass?._id,
      documentsKey: "sessionPasses",
      activityGroups,
      venues,
      activityFields,
      client,
      formTemplate,
      helpText:
        "Session passes can also be assigned to activities when they are created or edited.",
      initialValue: sessionPass?.activityGroups || [],
      condition: {
        when: "passCreditUsageOption",
        is: "selectedActivityGroups",
        then: { visible: true }
      }
    },
    {
      index: 3,
      arrayField: false,
      component: "checkbox-multiple",
      name: "allowedPaymentMethods",
      label: "Allowed payment methods",
      isRequired: false,
      formTemplate,
      options: enabledPaymentMethodInstances?.map(paymentMethod => ({
        value: paymentMethod._id,
        label: paymentMethod.name
      })),
      initialValue:
        sessionPass?.allowedPaymentMethods.map(
          paymentMethod => paymentMethod._id
        ) || []
    },
    {
      index: 4,
      arrayField: false,
      component: componentTypes.CHECKBOX,
      name: "enabled",
      label: "Enabled",
      dataType: dataTypes.BOOLEAN,
      formTemplate,
      initialValue: sessionPass ? sessionPass.enabled : true,
      helpText:
        "Disabling prevents any new purchases but credits remaining on existing purchases can still be used."
    },
    {
      index: 5,
      component: "section-header",
      name: "purchaseOptionsHeader",
      title: "Purchase options",
      description: "The options for purchasing pass credits."
    },
    {
      index: 6,
      component: "session-pass-purchase-options",
      name: "purchaseOptions",
      formTemplate,
      label: "Purchase options",
      initialValue: sessionPass?.purchaseOptions,
      isNewSessionPass: !sessionPass
    }
  ];

  const schema = {
    fields
  };

  return schema;
};

interface GenerateSessionPassPurchaseOptionFormSchemaData {
  formTemplate?: FormTemplateType;
  prefix?: string;
  editingItem?: SessionPassPurchaseOption;
  client: Client;
}

export const generateSessionPassPurchaseOptionFormSchema = ({
  formTemplate = FormTemplateType.Default,
  prefix = "",
  editingItem,
  client
}: GenerateSessionPassPurchaseOptionFormSchemaData) => {
  const prefixPath = prefix ? `${prefix}.` : "";

  const fields = [
    {
      index: 0,
      arrayField: false,
      component: componentTypes.TEXT_FIELD,
      inputType: "number",
      name: `${prefixPath}creditAmount`,
      helpText:
        "Amount of session pass credits a user will receive when they purchase this option.",
      label: "Credit amount",
      isRequired: true,
      min: 1,
      validate: [
        {
          type: "required"
        }
      ],
      formTemplate,
      initialValue: editingItem?.creditAmount
    },
    {
      index: 1,
      arrayField: false,
      component: "currency",
      name: `${prefixPath}price`,
      label: "Price",
      isRequired: true,
      min: 0,
      step: "any",
      validate: [
        { type: "required" },
        (value: string) => {
          const valueParsed = parseInt(value, 10);

          if (valueParsed < 0) {
            return "Amount cannot be less than 0.";
          }

          return undefined;
        }
      ],
      formTemplate,
      initialValue: editingItem?.price
        ? getCurrencyAmountForDisplay(editingItem.price, client.currency)
        : null,
      client
    },
    {
      index: 2,
      arrayField: false,
      component: "date-validity",
      inputType: "number",
      name: `${prefixPath}expiresAfter`,
      helpText:
        "Period for which a pass will be valid after it's purchased by a user. Leave blank if you don't wish to set an expiry.",
      label: "Expires after",
      formTemplate,
      initialValue: editingItem?.expiresAfter
    }
  ];

  const schema = {
    fields
  };

  return schema;
};

interface GenerateAddSessionPassCreditFormSchemaData {
  formTemplate?: FormTemplateType;
  sessionPasses: SessionPass[];
}

export const generateAddSessionPassCreditFormSchema = ({
  formTemplate = FormTemplateType.Seamless,
  sessionPasses
}: GenerateAddSessionPassCreditFormSchemaData) => {
  const fields = [
    {
      index: 0,
      arrayField: false,
      component: componentTypes.SELECT,
      name: "sessionPassId",
      label: "Session pass",
      isRequired: true,
      validate: [{ type: "required" }],
      options: sessionPasses.map(sessionPass => ({
        value: sessionPass._id,
        label: sessionPass.name
      })),
      formTemplate
    },
    {
      index: 1,
      arrayField: false,
      component: componentTypes.TEXT_FIELD,
      inputType: "number",
      name: "creditAmount",
      helpText: "Amount of session pass credits to issue to the user.",
      label: "Credit amount",
      isRequired: true,
      min: 1,
      validate: [{ type: "required" }],
      formTemplate
    },
    {
      index: 2,
      arrayField: false,
      component: componentTypes.CHECKBOX,
      name: "specifyExpiry",
      label: "Specify expiry",
      helpText: null,
      isRequired: false,
      dataType: dataTypes.BOOLEAN,
      formTemplate
    },
    {
      index: 3,
      arrayField: false,
      component: componentTypes.DATE_PICKER,
      name: "expiry",
      label: "Expiry",
      isRequired: true,
      validate: [{ type: "required" }],
      hideLabel: true,
      formTemplate,
      condition: {
        when: "specifyExpiry",
        is: true,
        then: { visible: true }
      }
    }
  ];

  const schema = {
    fields
  };

  return schema;
};

export function generateDeductSessionPassCreditFormSchema({
  formTemplate = FormTemplateType.Seamless,
  creditPassTransactionToDeductCredit
}: {
  formTemplate?: FormTemplateType;
  creditPassTransactionToDeductCredit: SessionPassTransactionWithCreditUsage | null;
}) {
  if (!creditPassTransactionToDeductCredit) return null;

  const creditsUsed = Math.abs(creditPassTransactionToDeductCredit.creditUsage);

  const creditRemaining =
    creditPassTransactionToDeductCredit.amount - creditsUsed;

  const fields = [
    {
      index: 1,
      arrayField: false,
      component: componentTypes.TEXT_FIELD,
      inputType: "number",
      name: "creditAmount",
      helpText: `A maximum of ${creditRemaining} remaining credits can be deducted.`,
      label: "Credit amount to deduct",
      isRequired: true,
      max: creditRemaining,
      min: 1,
      validate: [{ type: "required" }],
      formTemplate
    }
  ];

  const schema = {
    fields
  };

  return schema;
}
