import type {
  UseFieldApiConfig,
  UseFieldApiProps
} from "@data-driven-forms/react-form-renderer/use-field-api";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import { SessionPassPurchaseOptionsAddEditModal } from "components/admin/SessionPassPurchaseOptionsAddEditModal";
import { TableWrapper } from "components/admin/table/TableWrapper";
import { convertAmountBasedOnCurrency } from "helpers/currency";
import { formatCurrency } from "helpers/helpers";
import { getExpiresTextValueFromInput } from "helpers/session-pass";
import useClient from "hooks/useClient";
import IconInformationCircleSolid from "public/images/icons/information-circle_solid.svg";
import IconPlusCircle from "public/images/icons/plus-circle.svg";
import { useState } from "react";
import { DateDuration } from "types/date";
import type { FormTemplateType } from "types/form";
import type { Currency } from "types/model/client";
import type {
  SessionPassPurchaseOption,
  SessionPassPurchaseOptionFormData
} from "types/model/session-pass";
import { cn } from "utils/cn";

interface SessionPassPurchaseOptionsProps
  extends UseFieldApiProps<SessionPassPurchaseOption[], HTMLElement> {
  isNewSessionPass?: boolean;
  formTemplate?: FormTemplateType;
}

export const SessionPassPurchaseOptions = (props: UseFieldApiConfig) => {
  const { isNewSessionPass, input, disabled }: SessionPassPurchaseOptionsProps =
    useFieldApi(props);
  const [isDirty, setIsDirty] = useState(false);

  const [editingItemId, setEditingItemId] = useState<string | null>(null);
  const [isAddEditItemModalOpen, setIsAddEditItemModalOpen] =
    useState<boolean>(false);

  const clientQueryInfo = useClient();

  const handleAddNewItem = () => {
    setIsAddEditItemModalOpen(true);
  };

  function onConfirmItemAddEdit(
    formData: SessionPassPurchaseOptionFormData,
    editingItemId: string | null = null
  ) {
    if (editingItemId) {
      const editingItemIndex = input.value.findIndex(
        item => item._id === editingItemId
      );
      const updatedItems = [...input.value];
      updatedItems[editingItemIndex] = {
        ...updatedItems[editingItemIndex],
        creditAmount: parseInt(formData.creditAmount, 10),
        price: convertAmountBasedOnCurrency(
          formData.price,
          clientQueryInfo.data?.currency as Currency
        ),
        expiresAfter: {
          amount: formData.expiresAfter?.amount
            ? parseInt(formData.expiresAfter.amount, 10)
            : null,
          unit: formData.expiresAfter?.unit || DateDuration.Days
        }
      };

      input.onChange(updatedItems);
    } else {
      const newItem: SessionPassPurchaseOption = {
        _id: `temp_${Math.random().toString(36).substring(7)}`,
        creditAmount: parseInt(formData.creditAmount, 10),
        price: convertAmountBasedOnCurrency(
          formData.price,
          clientQueryInfo.data?.currency as Currency
        ),
        expiresAfter: {
          amount: formData.expiresAfter?.amount
            ? parseInt(formData.expiresAfter.amount, 10)
            : null,
          unit: formData.expiresAfter?.unit || DateDuration.Days
        }
      };

      const updatedItems = [...input.value, newItem];
      input.onChange(updatedItems);
    }

    setEditingItemId(null);
    setIsDirty(true);
  }

  const onEditItem = (id: string) => {
    setEditingItemId(id);
    setIsAddEditItemModalOpen(true);
  };

  const onCancelAddEditItem = () => {
    setEditingItemId(null);
  };

  const onDeleteItem = (id: string) => {
    const updatedItems = input.value.filter(item => item._id !== id);

    input.onChange(updatedItems);
    setIsDirty(true);
  };

  return clientQueryInfo.data ? (
    <div className="mt-5 border-t border-gray-200 pt-5">
      {isDirty && !isNewSessionPass && (
        <div className="mb-5 rounded-md bg-blue-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <IconInformationCircleSolid
                width={20}
                height={20}
                className="h-5 w-5 text-blue-400"
              />
            </div>
            <div className="ml-3">
              <p className="text-sm text-blue-700">
                Changes will be applied on save.
              </p>
            </div>
          </div>
        </div>
      )}
      {input.value.length > 0 && (
        <TableWrapper shadow={false}>
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th className="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                  Credit amount
                </th>
                <th className="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                  Price
                </th>
                <th className="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                  Expiry
                </th>
                <th className="bg-gray-50 px-6 py-3"></th>
              </tr>
            </thead>
            <tbody>
              {input.value
                .sort((a, b) => (a.creditAmount > b.creditAmount ? 1 : -1))
                .map((item, index) => (
                  <tr className="bg-white" key={index}>
                    <td className="whitespace-no-wrap px-6 py-4 text-sm font-medium text-gray-900">
                      {item.creditAmount} session credits
                    </td>
                    <td className="whitespace-no-wrap px-6 py-4 text-sm text-gray-500">
                      {formatCurrency({
                        rawAmount: item.price,
                        currency: clientQueryInfo.data?.currency as string
                      })}
                    </td>
                    <td className="whitespace-no-wrap px-6 py-4 text-sm text-gray-500">
                      {getExpiresTextValueFromInput(item.expiresAfter)}
                    </td>
                    <td className="whitespace-no-wrap cursor-pointer px-6 py-4 text-right text-sm font-medium">
                      <a
                        className="text-indigo-600 hover:text-indigo-900"
                        onClick={() => !disabled && onEditItem(item._id)}
                      >
                        Edit
                      </a>
                      {" | "}
                      <a
                        href="#"
                        className="text-indigo-600 hover:text-indigo-900"
                        onClick={() => !disabled && onDeleteItem(item._id)}
                      >
                        Delete
                      </a>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </TableWrapper>
      )}
      {!disabled && (
        <div className={cn(input.value.length > 0 && "mt-4")}>
          <span className="inline-flex rounded-md shadow-sm">
            <button
              type="button"
              className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={handleAddNewItem}
            >
              <IconPlusCircle
                width={24}
                height={24}
                className="-ml-1 mr-2 h-5 w-5"
              />
              Add new option
            </button>
          </span>
        </div>
      )}
      <SessionPassPurchaseOptionsAddEditModal
        isOpen={isAddEditItemModalOpen}
        client={clientQueryInfo.data}
        editingItemId={editingItemId}
        setIsOpen={setIsAddEditItemModalOpen}
        onCancel={onCancelAddEditItem}
        onConfirmItemAddEdit={onConfirmItemAddEdit}
      />
    </div>
  ) : null;
};
