import type { DateDuration } from "types/date";
import type { ActivityGroup } from "types/model/activity-group";
import type { Booking } from "types/model/booking";
import type { LineItem } from "types/model/line-item";

export enum SessionPassTransactionType {
  Credit = "credit",
  Debit = "debit"
}

export interface SessionPassExpiresAfter {
  amount: number | null;
  unit: DateDuration;
}

export enum PassesView {
  Active = "active",
  Inactive = "inactive"
}

export interface SessionPassPurchaseOption {
  _id: any;
  creditAmount: number;
  price: number;
  expiresAfter: SessionPassExpiresAfter;
}

export interface SessionPass<AGs = string[], PMs = string[]> {
  _id: any;
  name: string;
  purchaseOptions: SessionPassPurchaseOption[];
  allowedPaymentMethods: PMs;
  activityGroups: AGs;
  canBeUsedForAllActivityGroups: boolean;
  client: string;
  enabled: boolean;
  deleted: boolean;
  toObject: () => SessionPass<AGs, PMs>;
}

export enum PassCreditUsageOption {
  AllActivityGroups = "allActivityGroups",
  SelectedActivityGroups = "selectedActivityGroups"
}

export enum SessionPassCreditTransactionStatus {
  Active = "active",
  FullyUsed = "fullyUsed",
  Expired = "expired",
  PassDeleted = "passDeleted"
}

export interface SessionPassFormData<AGs = ActivityGroup[]> {
  name?: string;
  purchaseOptions?: SessionPassPurchaseOption[];
  activityGroups?: AGs;
  passCreditUsageOption?: PassCreditUsageOption;
  enabled?: boolean;
  allowedPaymentMethods?: string[];
}

export interface SessionPassPurchaseOptionFormData {
  creditAmount: string;
  price: string;
  expiresAfter: {
    amount: string;
    unit: DateDuration;
  };
}

export interface SessionPassTransaction<
  SP = string,
  CT = string,
  LI = string,
  DT = string
> {
  _id: any;
  type: SessionPassTransactionType;
  amount: number;
  sessionPass: SP;
  expires: Date;
  lineItem: LI;
  user: string;
  client: string;
  created: Date;
  updated: Date;
  creditTransactionUsedAgainst?: CT;
  debitTransactions?: DT[];
  save: () => Promise<void>;
  toObject: () => SessionPassTransaction<SP, CT, LI, DT>;
}

export type SessionPassDebitTransactionWithBooking = SessionPassTransaction<
  string,
  string,
  LineItem<string, string, string[], Booking>
>;

export interface SessionPassTransactionWithCreditUsage<SP = string, CT = string>
  extends SessionPassTransaction<
    SP,
    CT,
    string,
    SessionPassDebitTransactionWithBooking
  > {
  creditUsage: number;
  passStatus: SessionPassCreditTransactionStatus;
}

export interface SessionPassCreditAmount {
  sessionPass: SessionPass;
  creditAmount: number;
}

export interface SessionPassCreditsThatCanBeApplied {
  sessionPass: SessionPass;
  creditAmount: number;
  sessionCount: number;
  activityLineItemsCreditUsedFor: LineItem[];
}

export interface SessionPassCreditToIssue {
  sessionPassId: string;
  creditAmount: number;
}

export interface CreateSessionPassBookingData {
  sessionPassId: string;
  sessionPassPurchaseOptionId: string;
}

export interface SessionPassBooking {
  _id: string;
  user: string;
  sessionPass: SessionPass<string[], string[]>;
  sessionPassPurchaseOption: SessionPassPurchaseOption;
  status: SessionPassBookingStatus;
  stripePaymentIntentId: string;
  save: () => Promise<void>;
  toObject: () => SessionPassBooking;
}

export enum SessionPassBookingStatus {
  Pending = "pending",
  Completed = "completed"
}

export interface SessionPassCreditsToApply {
  sessionPassId: string;
  amount: number;
}

export interface SessionPassAddCreditFormData {
  userId: string;
  sessionPassId: string;
  creditAmount: string;
  specifyExpiry?: boolean;
  expiry?: string;
}

export interface SessionPassDeductCreditFormData {
  sessionPassTransactionId: string;
  userId: string;
  creditAmount: string;
}

export interface ApplySessionPassCreditsToBookingFormData {
  bookingId: string;
  creditsToApply: SessionPassCreditsToApply[];
}

export interface TotalForSessionItem {
  lineItemId: any;
  activityId: string;
  total: number;
}

export interface PassDebitItemToAdjust {
  lineItem: LineItem;
  adjustmentAmount: number;
  newTotal: number;
  newCreditUsage: number;
}

export interface PassDebitRefundCreditAmount {
  passDebitLineItemId: string;
  creditAmount: number;
}
