import type {
  UseFieldApiConfig,
  UseFieldApiProps
} from "@data-driven-forms/react-form-renderer/use-field-api";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import useFormApi from "@data-driven-forms/react-form-renderer/use-form-api";
import { HelpText } from "components/form/HelpText";
import { renderActivityDateString } from "helpers/helpers";
import { kebabCase } from "lodash";
import type { ChangeEvent } from "react";
import { FormTemplateType } from "types/form";
import type { ActivityDate } from "types/model/activity";
import type { Client } from "types/model/client";
import { cn } from "utils/cn";

interface SessionOptionsProps extends UseFieldApiProps<string[], HTMLElement> {
  label?: string;
  index?: number;
  isRequired?: boolean;
  helpText?: string;
  inModal?: boolean;
  formTemplate?: FormTemplateType;
  arrayField?: boolean;
  client?: Client;
  marginTop?: number;
}

export const SessionOptions = (props: UseFieldApiConfig) => {
  const {
    label,
    input,
    isRequired,
    meta: { error, touched },
    index,
    helpText,
    inModal,
    arrayField,
    formTemplate = FormTemplateType.Default,
    client,
    marginTop = 5
  }: SessionOptionsProps = useFieldApi(props);

  const isDefaultFormTemplate = formTemplate === FormTemplateType.Default;
  const isSeamlessFormTemplate = formTemplate === FormTemplateType.Seamless;
  const isFirstItem = index === 0;

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    const target = event.target;
    const updatedValue = target.checked
      ? [...input.value, target.id]
      : input.value.filter(item => item !== target.id);
    input.onChange(updatedValue);
  }

  const { getState } = useFormApi();
  const { values } = getState();

  const { datesInstances } = values as {
    datesInstances: { _id: string; date: ActivityDate }[];
  };

  const marginTopClass = `mt-${marginTop}`;

  return (
    <div
      className={cn(
        !inModal &&
          isDefaultFormTemplate &&
          "sm:grid sm:grid-cols-3 sm:items-start sm:gap-4",
        isDefaultFormTemplate &&
          !arrayField &&
          !isFirstItem &&
          `${marginTopClass} border-t border-gray-200 pt-5`,
        (isSeamlessFormTemplate || arrayField) && !isFirstItem && marginTopClass
      )}
      data-cy={`form-field-${kebabCase(label)}`}
    >
      <label className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
        {label}
        {isRequired && "*"}
      </label>
      <div
        className={cn(
          isDefaultFormTemplate && "mt-1 sm:col-span-2 sm:max-w-sm",
          isSeamlessFormTemplate && "mt-3"
        )}
      >
        <div className="space-y-4">
          {datesInstances.map(session => (
            <div key={session._id} className="relative flex items-start">
              <div className="flex h-5 items-center">
                <input
                  id={session._id}
                  name={session._id}
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  checked={input.value.includes(session._id)}
                  onChange={handleChange}
                  data-cy={`form-field-${kebabCase(session._id)}`}
                />
              </div>
              <div className="ml-3 text-sm">
                <label htmlFor={session._id} className="text-gray-700">
                  {renderActivityDateString({
                    activityDate: session.date as ActivityDate,
                    dateOnly: false,
                    timeOnly: false,
                    timeZone: client?.timeZone as string
                  })}
                </label>
              </div>
            </div>
          ))}
        </div>
        {helpText && <HelpText label={label || ""} helpText={helpText} />}
        {touched && error && (
          <p className="mt-3 text-sm text-red-600">{error}</p>
        )}
      </div>
    </div>
  );
};
