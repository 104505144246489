import axios from "axios";
import { produce } from "immer";
import type { MutationResultPair } from "react-query";
import { queryCache, useMutation } from "react-query";
import type { ErrorResponse } from "types";
import type { FormDataItem } from "types/form";
import type { Attendee } from "types/model/attendee";
import type { CurrentUser } from "types/model/user";
import { getErrorMessage } from "utils/getErrorMessage";

interface CreateAttendeeResponse {
  data: Attendee;
}

function useCreateCurrentUserAttendee(): MutationResultPair<
  CreateAttendeeResponse,
  ErrorResponse,
  FormDataItem[],
  unknown
> {
  return useMutation(
    async data => {
      try {
        return await axios.post("/api/user/attendees", data);
      } catch (error: unknown) {
        throw new Error(
          getErrorMessage({
            error,
            errorMessage: error =>
              `Failed to create attendee: ${error.message}`,
            otherErrorMessage: "Failed to create attendee."
          })
        );
      }
    },
    {
      onSuccess: response => {
        const newAttendee = response.data;

        const currentUserData: CurrentUser | undefined =
          queryCache.getQueryData("currentUser");

        if (!currentUserData) return;

        const currentUserDataUpdated = produce<CurrentUser>(
          currentUserData,
          (draft: CurrentUser) => {
            draft?.attendees?.push(newAttendee);
          }
        );

        queryCache.setQueryData("currentUser", currentUserDataUpdated);
        queryCache.invalidateQueries("currentUser");
      }
    }
  );
}

export default useCreateCurrentUserAttendee;
