export const QuantityWidget = ({
  quantity,
  onChange
}: {
  quantity: number;
  onChange: (action: string) => void;
}) => {
  return (
    <div className="inline-flex" data-cy="quantity-btns-container">
      <button
        type="button"
        onClick={() => onChange("decrease")}
        className="focus:shadow-outline-indigo relative inline-flex w-10 items-center rounded-l-md bg-indigo-600 px-4 py-2 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none active:bg-indigo-700 disabled:cursor-default disabled:opacity-50"
        aria-label="Remove"
        data-cy="btn-quantity-decrease"
      >
        -
      </button>
      <span className="flex w-11 items-center bg-gray-100 py-2 text-sm font-medium text-gray-700">
        <span className="w-full text-center" data-cy="quantity-amount">
          {quantity}
        </span>
      </span>
      <button
        type="button"
        onClick={() => onChange("increase")}
        className="focus:shadow-outline-indigo relative inline-flex w-10 items-center rounded-r-md bg-indigo-600 px-4 py-2 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none active:bg-indigo-700 disabled:cursor-default disabled:opacity-50"
        aria-label="Add"
        data-cy="btn-quantity-increase"
      >
        +
      </button>
    </div>
  );
};
