import WizardRoot from "@data-driven-forms/common/wizard";
import selectNext from "@data-driven-forms/common/wizard/select-next";
import FormSpy from "@data-driven-forms/react-form-renderer/form-spy";
import WizardContext from "@data-driven-forms/react-form-renderer/wizard-context";
import { ActivityFormSteps } from "components/admin/ActivityFormSteps";
import { SubmitButton } from "components/form/SubmitButton";
import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import type { ComponentType } from "react";
import { useContext } from "react";
import type { Step } from "types";

import type { Field } from "@data-driven-forms/react-form-renderer";

interface Props {
  name: string;
  activityGroupId?: string;
  steps: Step[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fields: Field<Record<string, any>, any>[];
}

const WizardInternal = ({ activityGroupId, steps }: Props) => {
  const {
    formOptions,
    currentStep,
    handlePrev,
    handleNext,
    activeStepIndex,
    jumpToStep
  } = useContext(WizardContext);

  const router = useRouter();

  function handlePrevEventHandler() {
    handlePrev();
  }

  function handleNextEventHandler() {
    const isValid = formOptions.getState().valid;
    const errors = formOptions.getState().errors;

    if (!isValid) {
      if (!isEmpty(errors)) {
        for (const key in errors) {
          formOptions.blur(key);
        }

        const firstEl = document.getElementById(Object.keys(errors)[0]);

        if (firstEl) {
          firstEl.scrollIntoView();
        }
      }

      return;
    }

    handleNext(selectNext(currentStep?.nextStep || "", formOptions.getState));
  }

  function handleJumpToStep(stepIdx: number) {
    const isForwardStep = stepIdx > activeStepIndex;
    const isValid = formOptions.getState().valid;

    if (!isValid && isForwardStep) return;

    jumpToStep(stepIdx);
  }

  function handleCancel() {
    if (activityGroupId) {
      router.push(`/admin/activities/${activityGroupId}`);
    } else {
      router.push("/admin/activities");
    }
  }

  return (
    <div>
      <ActivityFormSteps
        jumpToStep={handleJumpToStep}
        activeStepIndex={activeStepIndex}
        steps={steps}
      />
      {formOptions.renderForm(currentStep.fields)}
      <FormSpy>
        {() => (
          <div
            className="sticky -bottom-6 z-20 mt-8 border-t border-gray-200 bg-white py-5"
            style={{ backgroundColor: "rgba(256, 256, 256, 0.9)" }}
            data-cy="wizard-buttons"
          >
            <div className="flex justify-end space-x-3">
              {activeStepIndex > 0 ? (
                <button
                  type="button"
                  className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={handlePrevEventHandler}
                  data-cy="btn-back"
                >
                  Back
                </button>
              ) : (
                <button
                  type="button"
                  className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={handleCancel}
                  data-cy="btn-cancel"
                >
                  Cancel
                </button>
              )}
              {currentStep.nextStep && (
                <button
                  type="button"
                  className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={handleNextEventHandler}
                  data-cy="btn-next"
                >
                  Next
                </button>
              )}
              {(!currentStep.nextStep || activityGroupId) && (
                <SubmitButton
                  buttonType="button"
                  disabled={formOptions.getState().submitting}
                  isLoading={formOptions.getState().submitting}
                  // onClick={() => formOptions.handleSubmit()}
                  onClick={() => {
                    const submitBtn: HTMLButtonElement | null =
                      document.querySelector("#form-submit-button");

                    if (submitBtn) {
                      submitBtn.click();
                    }
                  }}
                >
                  {activityGroupId ? "Save" : "Submit"}
                </SubmitButton>
              )}
            </div>
          </div>
        )}
      </FormSpy>
    </div>
  );
};

export const Wizard = (props: Props) => {
  return (
    <WizardRoot Wizard={WizardInternal as ComponentType<unknown>} {...props} />
  );
};
