import type {
  UseFieldApiConfig,
  UseFieldApiProps
} from "@data-driven-forms/react-form-renderer/use-field-api";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import { HelpText } from "components/form/HelpText";
import { getSymbolForCurrencyInput } from "helpers/helpers";
import { kebabCase } from "lodash";
import IconExclamationCircleSolid from "public/images/icons/exclamation-circle_solid.svg";
import { FormTemplateType } from "types/form";
import type { Client, Currency as CurrencyT } from "types/model/client";
import { cn } from "utils/cn";

interface CurrencyProps extends UseFieldApiProps<string, HTMLElement> {
  label?: string;
  index?: number;
  isRequired?: boolean;
  helpText?: string;
  isSubFormSubmitted?: boolean;
  formTemplate?: FormTemplateType;
  arrayField?: boolean;
  client?: Client;
}

export const Currency = (props: UseFieldApiConfig) => {
  const {
    label,
    input,
    isRequired,
    meta: { error, touched },
    index,
    helpText,
    arrayField,
    formTemplate = FormTemplateType.Default,
    isSubFormSubmitted,
    client,
    ...rest
  }: CurrencyProps = useFieldApi(props);

  const isDefaultFormTemplate = formTemplate === FormTemplateType.Default;
  const isSeamlessFormTemplate = formTemplate === FormTemplateType.Seamless;
  const isFirstItem = index === 0;

  const touchedOrSubFormSubmitted = touched || isSubFormSubmitted;

  return (
    <div
      className={cn(
        isDefaultFormTemplate &&
          "sm:grid sm:grid-cols-3 sm:items-start sm:gap-4",
        isDefaultFormTemplate &&
          !arrayField &&
          !isFirstItem &&
          "mt-5 border-t border-gray-200 pt-5",
        (isSeamlessFormTemplate || arrayField) && !isFirstItem && "mt-5"
      )}
      data-cy={`form-field-${kebabCase(label)}`}
    >
      <label
        className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
        htmlFor={input.name}
      >
        {label}
        {isRequired && "*"}
      </label>
      <div
        className={cn(
          "mt-1",
          isDefaultFormTemplate && "sm:col-span-2 sm:max-w-sm"
        )}
      >
        <div className="relative">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <span className="text-gray-500 sm:text-sm sm:leading-5">
              {getSymbolForCurrencyInput(client?.currency as CurrencyT)}
            </span>
          </div>
          <input
            id={input.name}
            type="number"
            className={cn(
              "block w-full rounded-md border-gray-300 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm",
              getSymbolForCurrencyInput(client?.currency as CurrencyT) &&
                "pl-7",
              touchedOrSubFormSubmitted &&
                error &&
                "focus:shadow-outline-red border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-300"
            )}
            data-cy={`input-${kebabCase(label)}`}
            {...input}
            {...rest}
          />
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <span className="text-gray-500 sm:text-sm sm:leading-5">
              {client?.currency.toUpperCase()}
            </span>
          </div>
          {touchedOrSubFormSubmitted && error && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <IconExclamationCircleSolid
                width={20}
                height={20}
                className="h-5 w-5 text-red-500"
                data-cy={`icon-exclamation-${kebabCase(label)}`}
              />
            </div>
          )}
        </div>
        {helpText && <HelpText label={label || ""} helpText={helpText} />}
        {touchedOrSubFormSubmitted && error && (
          <p
            className="mt-2 text-sm text-red-600"
            data-cy={`error-${kebabCase(label)}`}
          >
            {error}
          </p>
        )}
      </div>
    </div>
  );
};
