import type { UseFieldApiConfig } from "@data-driven-forms/react-form-renderer/use-field-api";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import { CreateVenueModal } from "components/admin/CreateVenueModal";
import { HelpText } from "components/form/HelpText";
import { kebabCase } from "lodash";
import IconExclamationCircleSolid from "public/images/icons/exclamation-circle_solid.svg";
import type { ChangeEvent } from "react";
import { useState } from "react";
import { FormTemplateType } from "types/form";
import type { FormOption } from "types/model/form";
import { cn } from "utils/cn";

export const Venue = (props: UseFieldApiConfig) => {
  const {
    label,
    helpText,
    input,
    isRequired,
    index,
    options,
    arrayField,
    formTemplate = FormTemplateType.Default,
    inModal,
    client,
    meta: { error, touched },
    ...rest
  } = useFieldApi(props);

  const isDefaultFormTemplate = formTemplate === FormTemplateType.Default;
  const isSeamlessFormTemplate = formTemplate === FormTemplateType.Seamless;
  const [isCreateVenueModalOpen, setIsCreateVenueModalOpen] = useState(false);
  const isFirstItem = index === 0;

  function handleVenueSelect(e: ChangeEvent<HTMLSelectElement>) {
    if (e.target.value === "new") {
      setIsCreateVenueModalOpen(true);
    } else {
      input.onChange(e.target.value);
    }
  }

  function setVenueFieldValue(value: string) {
    input.onChange(value);
  }

  return (
    <div
      className={cn(
        !inModal &&
          isDefaultFormTemplate &&
          "sm:grid sm:grid-cols-3 sm:items-start sm:gap-4",
        isDefaultFormTemplate &&
          !arrayField &&
          !isFirstItem &&
          "mt-5 border-t border-gray-200 pt-5",
        (isSeamlessFormTemplate || arrayField) && !isFirstItem && "mt-5"
      )}
    >
      <label
        className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
        htmlFor={input.name}
      >
        {label}
        {isRequired && "*"}
      </label>
      <div className={cn(isDefaultFormTemplate && "sm:col-span-2 sm:max-w-sm")}>
        <div className="relative">
          <select
            id={input.name}
            className={cn(
              "mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm",
              touched &&
                error &&
                "focus:shadow-outline-red border-red-300 text-red-900 placeholder-red-300 focus:border-red-300"
            )}
            data-cy={`select-${kebabCase(label)}`}
            {...input}
            {...rest}
            onChange={handleVenueSelect}
          >
            <option value="">Please select</option>
            {options.map(({ value, label }: FormOption, i: number) => {
              return (
                <option key={i} value={value}>
                  {label}
                </option>
              );
            })}
            <option value="new">Create new</option>
          </select>
          {touched && error && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <IconExclamationCircleSolid
                width={20}
                height={20}
                className="h-5 w-5 bg-white text-red-500"
              />
            </div>
          )}
        </div>

        {helpText && <HelpText label={label} helpText={helpText} />}
        {touched && error && (
          <p className="mt-2 text-sm text-red-600">{error}</p>
        )}
      </div>
      <CreateVenueModal
        isOpen={isCreateVenueModalOpen}
        isSubform={true}
        client={client}
        setIsOpen={setIsCreateVenueModalOpen}
        setVenueFieldValue={setVenueFieldValue}
      />
    </div>
  );
};
