import type {
  UseFieldApiConfig,
  UseFieldApiProps
} from "@data-driven-forms/react-form-renderer/use-field-api";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import useFormApi from "@data-driven-forms/react-form-renderer/use-form-api";
import { XCircleIcon } from "@heroicons/react/20/solid";
import { AdminBookingItemsAddAttendeeModal } from "components/admin/AdminBookingItemsAddAttendeeModal";
import { AdminBookingViewEditAttendeeDetailsModal } from "components/admin/AdminBookingViewEditAttendeeDetailsModal";
import { CreateBookingItemAlert } from "components/admin/CreateBookingItemWarning";
import { Loader } from "components/Loader";
import { SelectedAddOns } from "components/site/SelectedAddOns";
import { compareAsc } from "date-fns";
import { getIsAttendeeOutsideAgeRestriction } from "helpers/activity";
import { getAreRequiredFieldsCompleted } from "helpers/admin-booking";
import {
  getActivityTitle,
  getActivityVenueName,
  getAttendeeFullName,
  renderActivityDateString
} from "helpers/helpers";
import useActivityGroupSalesForAttendee from "hooks/useActivityGroupSalesForAttendee";
import useUserAttendees from "hooks/useUserAttendees";
import { kebabCase } from "lodash";
import { useEffect, useState } from "react";
import { queryCache } from "react-query";
import { TicketType } from "types/model/activity";
import type {
  ActivityGroupFullListItem,
  ActivityGroupSalesForAttendeeData
} from "types/model/activity-group";
import type {
  CreateAdminBookingFormData,
  CreateAdminBookingItem,
  CreateAdminBookingItemsByActivityGroup,
  EmptyAttendee
} from "types/model/admin-booking";
import type { Attendee } from "types/model/attendee";
import type { Client } from "types/model/client";
import type { Field } from "types/model/field";
import type { Venue } from "types/model/venue";
import { cn } from "utils/cn";

function getAttendeesWithAlreadySelectedFilteredOut(
  attendees: Attendee[],
  attendeeItems: (Attendee | EmptyAttendee)[],
  currentIndex: number
): Attendee[] {
  return attendees.filter(
    attendee =>
      !attendeeItems.some(
        (attendeeItem, index) =>
          attendeeItem._id === attendee._id && index !== currentIndex
      )
  );
}

function getIsAttendeeAlreadyBookedForTicket({
  activityGroupId,
  bookingItem,
  attendee,
  activitySalesForAttendees
}: {
  activityGroupId: string;
  bookingItem: CreateAdminBookingItem;
  attendee: Attendee | EmptyAttendee;
  activitySalesForAttendees: ActivityGroupSalesForAttendeeData;
}): boolean {
  // Return false if empty attendee
  if (attendee._id === "") {
    return false;
  }

  const ticketType = bookingItem.ticket.type;

  if (ticketType === TicketType.Single) {
    return Boolean(
      activitySalesForAttendees[activityGroupId]?.[attendee._id]
        ?.singleSessionTicketSales[bookingItem.ticket._id]?.[
        bookingItem.activities[0]?._id
      ]
    );
  } else if (ticketType === TicketType.All) {
    return Boolean(
      activitySalesForAttendees[activityGroupId]?.[attendee._id]
        ?.allSessionTicketSales[bookingItem.ticket._id]
    );
  }

  return false;
}

function getAreAllItemsAssignedAnAttendee(
  bookingItems: CreateAdminBookingItemsByActivityGroup[],
  activityGroupSalesForAttendeeData: ActivityGroupSalesForAttendeeData,
  areRequiredFieldsCompletedHash: { [key: string]: boolean }
): boolean {
  const areAllBookingItemsAssigned = bookingItems.every(bookingItem => {
    return bookingItem.items.every(item => {
      return item.attendees.every(attendee => {
        const isAttendeeAlreadyBookedForTicket =
          getIsAttendeeAlreadyBookedForTicket({
            activityGroupId: bookingItem.activityGroup._id,
            bookingItem: item,
            attendee,
            activitySalesForAttendees: activityGroupSalesForAttendeeData
          });
        return (
          attendee._id !== "" &&
          !isAttendeeAlreadyBookedForTicket &&
          areRequiredFieldsCompletedHash[
            `${bookingItem.activityGroup._id}_${attendee._id}`
          ]
        );
      });
    });
  });

  return areAllBookingItemsAssigned;
}

interface AdminBookingItemsAssignAttendeeProps
  extends UseFieldApiProps<unknown, HTMLElement> {
  activityGroups?: ActivityGroupFullListItem[];
  venues?: Venue[];
  activityFields?: Field[];
  attendeeFields?: Field[];
  client?: Client;
}

export const AdminBookingItemsAssignAttendee = (props: UseFieldApiConfig) => {
  const {
    label,
    meta: { error, touched },
    input,
    attendeeFields,
    client
  }: AdminBookingItemsAssignAttendeeProps = useFieldApi(props);

  const { getState, change } = useFormApi();
  const formState = getState();

  const values = formState.values as CreateAdminBookingFormData;

  const userAttendeesInfo = useUserAttendees(values.userId);
  const [isAddAttendeeModalOpen, setIsAddAttendeeModalOpen] =
    useState<boolean>(false);

  const [isViewAttendeeDetailsModalOpen, setIsViewAttendeeDetailsModalOpen] =
    useState<boolean>(false);
  const [selectedAttendeeToView, setSelectedAttendeeToView] =
    useState<Attendee | null>(null);

  const [activityGroupId, setActivityGroupId] = useState<string>("");
  const [itemId, setItemId] = useState<string>("");
  const [index, setIndex] = useState<number>(0);

  const [areRequiredFieldsCompletedHash, setAreRequiredFieldsCompletedHash] =
    useState<{ [key: string]: boolean }>({});

  const [
    isAttendeeOutsideAgeRestrictionHash,
    setIsAttendeeOutsideAgeRestrictionHash
  ] = useState<{ [key: string]: boolean }>({});

  const activityGroupIds: string[] = values.bookingItems.reduce((acc, item) => {
    acc.push(item.activityGroup._id);

    return acc;
  }, [] as string[]);

  const activityGroupSalesForAttendeeQueryInfo =
    useActivityGroupSalesForAttendee(values.userId, activityGroupIds);

  useEffect(() => {
    if (userAttendeesInfo.data) {
      const areRequiredFieldsCompletedHash: { [key: string]: boolean } = {};
      const isAttendeeOutsideAgeRestrictionHash: { [key: string]: boolean } =
        {};

      userAttendeesInfo.data.forEach(attendee => {
        activityGroupIds.forEach(activityGroupId => {
          const activityGroup = values.bookingItems?.find(
            item => item.activityGroup._id === activityGroupId
          )?.activityGroup as ActivityGroupFullListItem;

          const areFieldsCompleted = getAreRequiredFieldsCompleted(
            attendee as Attendee,
            attendeeFields || [],
            activityGroupId
          );
          const isOutsideAgeRestriction = getIsAttendeeOutsideAgeRestriction(
            attendee as Attendee,
            activityGroup,
            activityGroup.activities
          );

          areRequiredFieldsCompletedHash[`${activityGroupId}_${attendee._id}`] =
            areFieldsCompleted;
          isAttendeeOutsideAgeRestrictionHash[
            `${activityGroupId}_${attendee._id}`
          ] = isOutsideAgeRestriction;
        });
      });
      setAreRequiredFieldsCompletedHash(areRequiredFieldsCompletedHash);
      setIsAttendeeOutsideAgeRestrictionHash(
        isAttendeeOutsideAgeRestrictionHash
      );

      const areAllBookingItemsAssigned = getAreAllItemsAssignedAnAttendee(
        values.bookingItems,
        activityGroupSalesForAttendeeQueryInfo.data as ActivityGroupSalesForAttendeeData,
        areRequiredFieldsCompletedHash
      );

      // Set the value of the field to 1 if all booking items are assigned
      const inputValue = areAllBookingItemsAssigned ? 1 : 0;
      input.onChange(inputValue);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAttendeesInfo.data]);

  function handleSelectAttendee(
    value: string,
    activityGroupId: string,
    itemId: string,
    index: number
  ) {
    if (value === "new-attendee") {
      setActivityGroupId(activityGroupId);
      setItemId(itemId);
      setIndex(index);
      setIsAddAttendeeModalOpen(true);
      return;
    }

    const attendeesQC: Attendee[] =
      queryCache.getQueryData(["userAttendees", values.userId]) || [];

    const attendee = attendeesQC.find(attendee => attendee._id === value) || {
      _id: ""
    };

    const activityGroupItem = values.bookingItems.find(
      item => item.activityGroup._id === activityGroupId
    );

    const item = activityGroupItem?.items.find(item => item._id === itemId);

    if (item) {
      const updatedAttendees = item.attendees.map((attendeeItem, i) => {
        if (i === index) {
          return attendee;
        }
        return attendeeItem;
      });
      const updatedItem = {
        ...item,
        attendees: updatedAttendees
      };
      const updatedBookingItems = values.bookingItems.map(item => {
        if (item.activityGroup._id === activityGroupId) {
          return {
            ...item,
            items: item.items.map(item => {
              if (item._id === itemId) {
                return updatedItem;
              }
              return item;
            })
          };
        }
        return item;
      });

      const areAllBookingItemsAssigned = getAreAllItemsAssignedAnAttendee(
        updatedBookingItems,
        activityGroupSalesForAttendeeQueryInfo.data as ActivityGroupSalesForAttendeeData,
        areRequiredFieldsCompletedHash
      );

      // Set the value of the field to 1 if all booking items are assigned
      const inputValue = areAllBookingItemsAssigned ? 1 : 0;

      input.onChange(inputValue);

      change("bookingItems", updatedBookingItems);
    }
  }

  function handleViewAttendeeDetails(
    attendeeId: string,
    activityGroupId: string
  ) {
    setActivityGroupId(activityGroupId);
    const selectedAttendee = userAttendeesInfo.data?.find(
      attendee => attendee._id === attendeeId
    ) as Attendee;

    setSelectedAttendeeToView(selectedAttendee);
    setIsViewAttendeeDetailsModalOpen(true);
  }

  return (
    <div className="mt-5 border-t border-gray-200 pt-5">
      {/* Lets the browser know where to jump to if there's a validation error */}
      <input
        type="text"
        name={input.name}
        style={{ border: 0, padding: 0, width: 0, height: 0, display: "block" }}
      />
      {touched && error && (
        <>
          <div className="mb-5 rounded-md bg-red-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <XCircleIcon
                  className="h-5 w-5 text-red-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <p
                  className="text-sm text-red-700"
                  data-cy={`error-${kebabCase(label)}`}
                >
                  {error}
                </p>
              </div>
            </div>
          </div>
        </>
      )}
      {userAttendeesInfo.isLoading ||
      activityGroupSalesForAttendeeQueryInfo.isLoading ? (
        <Loader />
      ) : userAttendeesInfo.data &&
        activityGroupSalesForAttendeeQueryInfo.data ? (
        <>
          <div className="divide-y divide-gray-200">
            {values.bookingItems.map((activityGroupItem, index) => (
              <div
                className={cn(
                  index === 0 && "pb-6 sm:pb-10",
                  index > 0 && "py-6 sm:py-10"
                )}
                key={activityGroupItem.activityGroup._id}
              >
                <h3 className="mb-4 font-medium text-gray-900">
                  {getActivityTitle(activityGroupItem.activityGroup)},{" "}
                  {getActivityVenueName(activityGroupItem.activityGroup)}
                </h3>
                <ul className="space-y-8">
                  {activityGroupItem.items.map(item => (
                    <li key={item._id}>
                      <div className="mb-2">
                        <span className="block text-sm font-medium leading-6 text-gray-900">
                          {item.ticket?.name}
                        </span>
                        <span className="block text-sm leading-6 text-gray-900">
                          <div>
                            {item.ticket?.type === TicketType.Single ? (
                              <span className="block">
                                {renderActivityDateString({
                                  activityDate: item.activities[0]?.date,
                                  dateOnly: false,
                                  timeOnly: false,
                                  timeZone: client?.timeZone as string
                                })}
                              </span>
                            ) : item.ticket?.type === TicketType.All &&
                              item.activities?.length === 1 ? (
                              <span className="block">
                                {renderActivityDateString({
                                  activityDate: item.activities[0].date,
                                  dateOnly: false,
                                  timeOnly: false,
                                  timeZone: client?.timeZone as string
                                })}
                              </span>
                            ) : item.ticket?.type === TicketType.All &&
                              item.activities?.filter(
                                activity => activity.enabled
                              ).length > 1 ? (
                              <div>
                                {
                                  item.activities.filter(
                                    activity => activity.enabled
                                  ).length
                                }{" "}
                                sessions from{" "}
                                {renderActivityDateString({
                                  activityDate: item.activities
                                    .filter(activity => activity.enabled)
                                    .sort((a, b) =>
                                      compareAsc(
                                        new Date(a.date.start),
                                        new Date(b.date.start)
                                      )
                                    )[0].date,
                                  dateOnly: true,
                                  timeOnly: false,
                                  timeZone: client?.timeZone as string
                                })}
                              </div>
                            ) : null}
                          </div>
                        </span>
                        {item.addOns?.length > 0 && (
                          <SelectedAddOns
                            addOns={item.addOns}
                            client={client as Client}
                          />
                        )}
                      </div>
                      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                        {item.attendees.map((attendee, index) => {
                          return (
                            <div
                              key={`attendee-${index}`}
                              className="sm:max-w-lg"
                            >
                              <label
                                htmlFor={`attendee-${index}`}
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                Attendee
                                {item.attendees.length > 1
                                  ? ` ${index + 1}`
                                  : ""}
                              </label>
                              <select
                                id={`attendee-${index}`}
                                key={isAddAttendeeModalOpen ? "asdf" : "qwer"}
                                name={`attendee-${index}`}
                                className="mt-1 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                value={attendee._id || ""}
                                onChange={e =>
                                  handleSelectAttendee(
                                    e.target.value,
                                    activityGroupItem.activityGroup._id,
                                    item._id,
                                    index
                                  )
                                }
                              >
                                <option value="">Select attendee</option>
                                {getAttendeesWithAlreadySelectedFilteredOut(
                                  userAttendeesInfo.data || [],
                                  item.attendees,
                                  index
                                ).map(attendee => {
                                  return (
                                    <option
                                      key={attendee._id}
                                      value={attendee._id}
                                    >
                                      {getAttendeeFullName(attendee)}
                                    </option>
                                  );
                                })}
                                <option value="new-attendee">
                                  New attendee
                                </option>
                              </select>
                              {attendee._id && (
                                <>
                                  <a
                                    onClick={() =>
                                      handleViewAttendeeDetails(
                                        attendee._id,
                                        activityGroupItem.activityGroup._id
                                      )
                                    }
                                    className="mt-0.5 cursor-pointer text-sm font-medium text-indigo-600 hover:text-indigo-500"
                                  >
                                    View/Edit attendee details
                                  </a>
                                  <>
                                    {!areRequiredFieldsCompletedHash[
                                      `${activityGroupItem.activityGroup._id}_${attendee._id}`
                                    ] && (
                                      <CreateBookingItemAlert color="red">
                                        Additional information is required for
                                        this attendee
                                      </CreateBookingItemAlert>
                                    )}
                                  </>
                                  <>
                                    {isAttendeeOutsideAgeRestrictionHash[
                                      `${activityGroupItem.activityGroup._id}_${attendee._id}`
                                    ] && (
                                      <CreateBookingItemAlert color="yellow">
                                        Attendee is outside of the set age
                                        restriction
                                      </CreateBookingItemAlert>
                                    )}
                                  </>
                                </>
                              )}
                              {getIsAttendeeAlreadyBookedForTicket({
                                activityGroupId:
                                  activityGroupItem.activityGroup._id,
                                bookingItem: item,
                                attendee,
                                activitySalesForAttendees:
                                  activityGroupSalesForAttendeeQueryInfo.data as ActivityGroupSalesForAttendeeData
                              }) && (
                                <CreateBookingItemAlert color="red">
                                  Attendee has already been booked using this
                                  ticket
                                </CreateBookingItemAlert>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
          <AdminBookingViewEditAttendeeDetailsModal
            attendee={selectedAttendeeToView}
            attendeeFields={attendeeFields || []}
            activityGroupId={activityGroupId}
            isOpen={isViewAttendeeDetailsModalOpen}
            client={client as Client}
            setIsOpen={setIsViewAttendeeDetailsModalOpen}
          />
          <AdminBookingItemsAddAttendeeModal
            userId={values.userId}
            activityGroupId={activityGroupId}
            itemId={itemId}
            index={index}
            attendeeFields={attendeeFields || []}
            isOpen={isAddAttendeeModalOpen}
            client={client as Client}
            handleSelectAttendee={handleSelectAttendee}
            setIsOpen={setIsAddAttendeeModalOpen}
          />
        </>
      ) : null}
    </div>
  );
};
