import type { UseFieldApiConfig } from "@data-driven-forms/react-form-renderer";
import { useFieldApi } from "@data-driven-forms/react-form-renderer";
import { PlusIcon as PlusIconSolidMini } from "@heroicons/react/20/solid";
import { CheckIcon } from "@heroicons/react/24/outline";
import { ActivityFiltersNoDateRange } from "components/admin/ActivityFiltersNoDateRange";
import { Loader } from "components/Loader";
import { getActivityTitle, getActivityVenueName } from "helpers/helpers";
import useActivityGroupsFullList from "hooks/useActivityGroupsFullList";
import { kebabCase } from "lodash";
import { useState } from "react";
import type { ActivityGroupFullListItem } from "types/model/activity-group";
import type { FieldFilters } from "types/model/field-data";

export const ActivityGroupsSelectOne = (props: UseFieldApiConfig) => {
  const [filters, setFilters] = useState<FieldFilters>({});

  const activityGroupsFullListQueryInfo = useActivityGroupsFullList(
    JSON.stringify(filters)
  );

  const {
    input,
    fields,
    venues,
    label,
    meta: { error, touched }
  } = useFieldApi(props);

  function handleSelectActivityGroup(activityGroup: ActivityGroupFullListItem) {
    input.onChange(activityGroup);
  }

  function handleRemoveActivityGroup() {
    input.onChange(undefined);
  }

  return (
    <div>
      <ActivityFiltersNoDateRange
        filterFields={fields}
        filters={filters}
        venuesList={venues}
        visibilityKey="useForAdminFiltering"
        inModal={true}
        innerClassName="pb-5"
        handleSetFilters={setFilters}
      />
      {activityGroupsFullListQueryInfo.isLoading ? (
        <Loader />
      ) : activityGroupsFullListQueryInfo.data ? (
        <>
          {activityGroupsFullListQueryInfo.data.length >= 1 ? (
            <>
              <ul
                role="list"
                className="mt-2 max-h-bananas divide-y divide-gray-200 overflow-y-scroll border-b border-t border-gray-200 pr-4 sm:max-h-oranges"
              >
                {activityGroupsFullListQueryInfo.data.map(activityGroup => (
                  <li
                    key={activityGroup._id}
                    className="flex items-center justify-between space-x-3 py-4 text-left"
                  >
                    <div className="flex-grow">
                      <div className="text-sm font-medium text-gray-700">
                        {getActivityTitle(activityGroup)}
                      </div>
                      <div className="text-sm text-gray-500">
                        {getActivityVenueName(activityGroup)}
                      </div>
                      <div className="text-sm text-gray-500">
                        {activityGroup.activityGroupStartEnd}
                      </div>
                    </div>
                    <div className="flex-shrink">
                      {input.value._id === activityGroup._id ? (
                        <button
                          type="button"
                          className="inline-flex items-center rounded-full border border-transparent bg-green-50 px-3 py-2 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                          onClick={handleRemoveActivityGroup}
                        >
                          <CheckIcon
                            className="-ml-1 mr-0.5 h-5 w-5 text-green-400"
                            aria-hidden="true"
                          />
                          <span className="text-sm font-medium text-green-900">
                            Selected
                          </span>
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="inline-flex items-center rounded-full border border-transparent bg-gray-100 px-3 py-2 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          onClick={() =>
                            handleSelectActivityGroup(activityGroup)
                          }
                        >
                          <PlusIconSolidMini
                            className="-ml-1 mr-0.5 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          <span className="text-sm font-medium text-gray-900">
                            Select
                          </span>
                        </button>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
              {touched && error && (
                <p
                  className="mt-2 text-sm text-red-600"
                  data-cy={`error-${kebabCase(label)}`}
                >
                  {error}
                </p>
              )}
            </>
          ) : (
            <p className="text-sm text-gray-500">No activities found</p>
          )}
        </>
      ) : null}
    </div>
  );
};
