import { getAccountCreditExpiresReadableValue } from "helpers/account-credit";
import { getRefundMethodReadableName } from "helpers/cancellations";
import { formatCurrency } from "helpers/helpers";
import type { CancellationPolicyRule } from "types/model/cancellation";
import { RefundMethod } from "types/model/cancellation";
import type { Client } from "types/model/client";

export const CancellationRuleConfig = ({
  cancellationRule,
  client
}: {
  cancellationRule: CancellationPolicyRule;
  client: Client;
}) => {
  return (
    <div className="space-y-4">
      <div>
        <dd className="text-sm font-medium text-gray-500">
          Percentage to refund
        </dd>
        <dt className="mt-0.5 text-sm text-gray-900">
          {cancellationRule.config.percentageToRefund}%
        </dt>
      </div>
      {cancellationRule.config.fee ? (
        <div>
          <dd className="text-sm font-medium text-gray-500">
            Cancellation fee
          </dd>
          <dt className="mt-0.5 text-sm text-gray-900">
            {formatCurrency({
              rawAmount: cancellationRule.config.fee,
              currency: client.currency
            })}
          </dt>
        </div>
      ) : null}
      {cancellationRule.config.refundMethods.length > 0 ? (
        <div>
          <dd className="text-sm font-medium text-gray-500">
            Enabled refund methods
          </dd>
          <dt className="mt-0.5 text-sm text-gray-900">
            {cancellationRule.config.refundMethods
              .map(method => {
                let refundMethodText = getRefundMethodReadableName(method);
                if (method === RefundMethod.AccountCredit) {
                  refundMethodText += cancellationRule.config
                    .accountCreditExpiry?.amount
                    ? ` (${getAccountCreditExpiresReadableValue(
                        cancellationRule.config.accountCreditExpiry
                      )} expiry)`
                    : " (no expiry)";
                }
                return refundMethodText;
              })
              .join(", ")}
          </dt>
        </div>
      ) : null}
    </div>
  );
};
