import type { UseFieldApiConfig } from "@data-driven-forms/react-form-renderer/use-field-api";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import { HelpText } from "components/form/HelpText";
import { kebabCase } from "lodash";
import { FormTemplateType } from "types/form";
import { cn } from "utils/cn";

// TODO: pass as props
const min = 30;
const max = 720;
const step = 30;
const unitName = "hours";
const unitSubtract = 60;
const valueTextWidth = 70;

export const RangeField = (props: UseFieldApiConfig) => {
  const {
    label,
    input,
    isRequired,
    meta: { error, touched },
    index,
    helpText,
    arrayField,
    inModal,
    formTemplate = FormTemplateType.Default
  } = useFieldApi(props);

  const isDefaultFormTemplate = formTemplate === FormTemplateType.Default;
  const isSeamlessFormTemplate = formTemplate === FormTemplateType.Seamless;
  const isFirstItem = index === 0;

  return (
    <div
      className={cn(
        !inModal &&
          isDefaultFormTemplate &&
          "sm:grid sm:grid-cols-3 sm:items-start sm:gap-4",
        isDefaultFormTemplate &&
          !arrayField &&
          !isFirstItem &&
          "mt-5 border-t border-gray-200 pt-5",
        (isSeamlessFormTemplate || arrayField) && !isFirstItem && "mt-5"
      )}
    >
      <label
        className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
        htmlFor={input.name}
      >
        {label}
        {isRequired && "*"}
      </label>
      <div>
        <div className="flex items-center justify-start">
          <input
            className="flex-grow"
            value={input.value}
            type="range"
            onChange={e => input.onChange(e.currentTarget.value)}
            min={min}
            max={max}
            step={step}
          />
          <div
            className="flex-shrink-0 text-right"
            style={{ width: valueTextWidth }}
          >
            <span className="text-sm text-gray-900">{`${
              input.value / unitSubtract
            } ${unitName}`}</span>
          </div>
        </div>
        {helpText && <HelpText label={label} helpText={helpText} />}
        {touched && error && (
          <p
            className="mt-2 text-sm text-red-600"
            data-cy={`error-${kebabCase(label)}`}
          >
            {error}
          </p>
        )}
      </div>
    </div>
  );
};
