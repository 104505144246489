import { componentTypes } from "@data-driven-forms/react-form-renderer";
import useFormApi from "@data-driven-forms/react-form-renderer/use-form-api";
import { Dialog, Transition } from "@headlessui/react";
import { SubmitButton } from "components/form/SubmitButton";
import { wait } from "helpers/helpers";
import { isEmpty } from "lodash";
import { Fragment, useRef } from "react";
import { FormTemplateType } from "types/form";

export interface ActivitySessionFormData {
  date: string;
  endTime: string;
  startTime: string;
  placeLimit?: string;
  repeatFrequency?: string;
  repeatOccurrences?: string;
}

export const ActivitySessionsBulkUpdatePlaceLimitsModal = ({
  isOpen,
  setIsOpen
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const { renderForm, getState, change } = useFormApi();

  const prefix = "placeLimitBulkUpdate";

  const formSchema = {
    fields: [
      {
        index: 3,
        arrayField: false,
        component: componentTypes.TEXT_FIELD,
        name: `${prefix}.placeLimit`,
        label: "Place limit",
        inputType: "number",
        isRequired: false,
        formTemplate: FormTemplateType.Seamless,
        validate: [
          (value: string) => {
            const valueParsed = parseInt(value, 10);

            return valueParsed < 1
              ? "If specifying a place limit it must be at least 1"
              : undefined;
          }
        ],
        helpText:
          "Sets the place limit all sessions. Leave blank to remove any limits that have been set."
      }
    ]
  };

  async function handleSubmit() {
    const formState = getState();
    const formData = formState.values;
    const formErrors = formState.errors?.[prefix];

    const hasErrors = !isEmpty(formErrors);

    if (hasErrors) return;

    const limit = formData[prefix]?.["placeLimit"];

    const updatedDatesInstances = formData.datesInstances.map(
      (dateInstance: { date: string; placeLimit: number }) => ({
        ...dateInstance,
        placeLimit: limit ?? null
      })
    );

    change("datesInstances", updatedDatesInstances);
    setIsOpen(false);
    change(prefix, undefined);
  }

  async function handleClose() {
    setIsOpen(false);
    await wait(300);
    change(prefix, undefined);
  }

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-10 overflow-y-auto"
        initialFocus={wrapperRef}
        open={isOpen}
        onClose={handleClose}
        data-cy="modal-bulk-update-place-limits"
      >
        <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block min-w-full transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:min-w-min sm:max-w-lg sm:align-middle">
              <div
                className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4"
                ref={wrapperRef}
              >
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="h-6 w-6 text-indigo-600"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16.5 8.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v8.25A2.25 2.25 0 006 16.5h2.25m8.25-8.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-7.5A2.25 2.25 0 018.25 18v-1.5m8.25-8.25h-6a2.25 2.25 0 00-2.25 2.25v6"
                      />
                    </svg>
                  </div>
                  <div className="mt-3 w-full sm:ml-4 sm:mt-0">
                    <Dialog.Title
                      as="h3"
                      className="text-center text-lg font-medium leading-6 text-gray-900 sm:text-left"
                    >
                      Bulk update session place limits
                    </Dialog.Title>
                    <div className="mb-4 mt-6">
                      {renderForm(formSchema.fields)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <SubmitButton
                  buttonType="button"
                  className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={handleSubmit}
                >
                  OK
                </SubmitButton>
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={handleClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
