import { createMarkup, getHtmlFromRaw, isJSON } from "helpers/string";
import { kebabCase } from "lodash";

export const HelpText = ({
  label,
  helpText
}: {
  label: string;
  helpText: string;
}) => {
  return (
    <>
      {isJSON(helpText) ? (
        <div
          className="prose prose-sm mt-2 text-sm text-gray-500"
          dangerouslySetInnerHTML={createMarkup(getHtmlFromRaw(helpText))}
          data-cy={`help-text-${kebabCase(label)}`}
        ></div>
      ) : (
        <p
          className="mt-2 text-sm text-gray-500"
          data-cy={`help-text-${kebabCase(label)}`}
        >
          {helpText}
        </p>
      )}
    </>
  );
};
