import type {
  UseFieldApiConfig,
  UseFieldApiProps
} from "@data-driven-forms/react-form-renderer/use-field-api";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { ActivityGroupsSelectModal } from "components/admin/ActivityGroupsSelectModal";
import { AttendeeFieldActivityGroupsSelectModal } from "components/admin/AttendeeFieldActivityGroupsSelectModal";
import { HelpText } from "components/form/HelpText";
import {
  getActivityGroupStartEnd,
  sortActivityGroupsByStartDate
} from "helpers/activity";
import { getActivityTitle, getActivityVenueName } from "helpers/helpers";
import { kebabCase } from "lodash";
import { useEffect, useState } from "react";
import { FormTemplateType } from "types/form";
import type { ActivityGroup } from "types/model/activity-group";
import type { Client } from "types/model/client";
import type { Field } from "types/model/field";
import type { Venue } from "types/model/venue";
import { cn } from "utils/cn";

interface ActivityGroupsProps
  extends UseFieldApiProps<ActivityGroup[], HTMLElement> {
  id?: string;
  venues?: Venue[];
  activityGroups?: ActivityGroup[];
  activityFields?: Field[];
  client?: Client;
  documentsKey?: string;
}

// TODO: look at combining with DiscountRuleActivityGroups

export const ActivityGroups = (props: UseFieldApiConfig) => {
  const {
    label,
    input,
    isRequired,
    meta: { error, touched },
    index,
    helpText,
    arrayField,
    formTemplate = FormTemplateType.Default,
    id,
    venues = [],
    activityGroups = [],
    activityFields = [],
    client,
    documentsKey
  }: ActivityGroupsProps = useFieldApi(props);

  const isDefaultFormTemplate = formTemplate === FormTemplateType.Default;
  const isSeamlessFormTemplate = formTemplate === FormTemplateType.Seamless;

  const isFirstItem = index === 0;
  const [isActivitiesSelectModalOpen, setIsActivitiesSelectModalOpen] =
    useState(false);
  const [selectedActivityGroupIds, setSelectedActivityGroupIds] = useState<
    string[]
  >([]);

  function handleActivityGroupRemove(activityGroupIdToRemove: string) {
    const updateActivityGroupIds = input.value.filter(
      activityGroup => activityGroup._id !== activityGroupIdToRemove
    );

    input.onChange(updateActivityGroupIds);
  }

  function handleUpdateActivityGroups(selectedActivityGroupIds: string[]) {
    const currentValue = input.value || [];
    const currentActivityGroupIds = activityGroups.map(
      activityGroup => activityGroup._id
    );
    const pastActivityGroups = currentValue.filter(
      activityGroup => !currentActivityGroupIds.includes(activityGroup._id)
    );
    const selectedActivityGroups = activityGroups.filter(activityGroup =>
      selectedActivityGroupIds.includes(activityGroup._id)
    );

    input.onChange([...pastActivityGroups, ...selectedActivityGroups]);
  }

  function resetSelectedActivityGroupIds() {
    const currentValue = input.value || [];
    setSelectedActivityGroupIds(
      currentValue.map(activityGroup => activityGroup._id)
    );
  }

  useEffect(() => {
    if (input.value) {
      setSelectedActivityGroupIds(
        input.value.map(activityGroup => activityGroup._id)
      );
    } else {
      setSelectedActivityGroupIds([]);
    }
  }, [input.value]);

  return (
    <div
      className={cn(
        isDefaultFormTemplate &&
          "sm:grid sm:grid-cols-3 sm:items-start sm:gap-4",
        isDefaultFormTemplate &&
          !arrayField &&
          !isFirstItem &&
          "mt-5 border-t border-gray-200 pt-5",
        (isSeamlessFormTemplate || arrayField) && !isFirstItem && "mt-5"
      )}
      data-cy={`form-field-${kebabCase(label)}`}
    >
      <label
        className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
        htmlFor={input.name}
      >
        {label}
        {isRequired && "*"}
      </label>
      <div className="relative mt-1 sm:max-w-sm">
        <fieldset>
          <legend className="sr-only">{label}</legend>
          <div>
            {input.value.length > 0 && (
              <ul
                role="list"
                className="mb-3 divide-y divide-gray-200 border-b border-gray-200"
              >
                {input.value
                  .sort(sortActivityGroupsByStartDate)
                  .map((activityGroup, index) => (
                    <li
                      key={activityGroup._id}
                      className={cn(
                        "flex items-center justify-between pb-3",
                        index !== 0 && "pt-3"
                      )}
                    >
                      <div>
                        <div className="text-sm font-medium text-gray-700">
                          {getActivityTitle(activityGroup)}
                        </div>
                        <div className="text-sm text-gray-500">
                          {getActivityVenueName(activityGroup)}
                        </div>
                        <div className="text-sm text-gray-500">
                          {client &&
                            getActivityGroupStartEnd(activityGroup, client)}
                        </div>
                      </div>
                      <button
                        type="button"
                        className="ml-6 rounded-md bg-white text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() =>
                          handleActivityGroupRemove(activityGroup._id)
                        }
                      >
                        Remove
                        <span className="sr-only">
                          {" "}
                          {getActivityTitle(activityGroup)}
                        </span>
                      </button>
                    </li>
                  ))}
              </ul>
            )}
            <button
              type="button"
              className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => setIsActivitiesSelectModalOpen(true)}
            >
              <PlusCircleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
              Select activites
            </button>
          </div>
          {/* TODO: Implement a better way of handling this logic
              - Essentially the difference with `AttendeeFieldActivityGroupsSelectModal`
                is that there is no restriction on only applying to 1 activity group
          */}
          {documentsKey && id ? (
            <ActivityGroupsSelectModal
              id={id}
              venues={venues}
              fields={activityFields}
              selectedActivityGroupIds={selectedActivityGroupIds}
              documentsKey={documentsKey}
              isOpen={isActivitiesSelectModalOpen}
              setSelectedActivityGroupIds={setSelectedActivityGroupIds}
              resetSelectedActivityGroupIds={resetSelectedActivityGroupIds}
              handleUpdateActivityGroups={handleUpdateActivityGroups}
              setIsOpen={setIsActivitiesSelectModalOpen}
            />
          ) : (
            <AttendeeFieldActivityGroupsSelectModal
              venues={venues}
              fields={activityFields}
              selectedActivityGroupIds={selectedActivityGroupIds}
              isOpen={isActivitiesSelectModalOpen}
              setSelectedActivityGroupIds={setSelectedActivityGroupIds}
              resetSelectedActivityGroupIds={resetSelectedActivityGroupIds}
              handleUpdateActivityGroups={handleUpdateActivityGroups}
              setIsOpen={setIsActivitiesSelectModalOpen}
            />
          )}
          {helpText && <HelpText label={label} helpText={helpText} />}
          {touched && error && (
            <p className="mt-5 text-sm text-red-600">{error}</p>
          )}
        </fieldset>
      </div>
    </div>
  );
};
