import Wizard from "@data-driven-forms/common/wizard";
import selectNext from "@data-driven-forms/common/wizard/select-next";
import type { Field } from "@data-driven-forms/react-form-renderer";
import FormSpy from "@data-driven-forms/react-form-renderer/form-spy";
import WizardContext from "@data-driven-forms/react-form-renderer/wizard-context";
import type { ComponentType } from "react";
import { useContext } from "react";

const subFormPrefix = "addBookingItemSubForm";

type Props = {
  name: string;
  activityGroupId?: string;
  handleSubmit: () => void;
  handleCancel: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fields: Field<Record<string, any>, any>[];
};

const BookingItemsWizard = ({
  activityGroupId,
  handleSubmit,
  handleCancel
}: Props) => {
  const { formOptions, currentStep, handlePrev, handleNext, activeStepIndex } =
    useContext(WizardContext);

  function handlePrevEventHandler() {
    handlePrev();
  }

  function handleNextEventHandler() {
    const { valid: isValid, errors } = formOptions.getState();
    const formErrors = errors?.[subFormPrefix];

    // triggers blur on all fields so meta touched is set to true
    if (!isValid && formErrors) {
      for (const key in formErrors) {
        formOptions.blur(`${subFormPrefix}.${key}`);
      }

      return;
    }

    handleNext(
      selectNext(currentStep.nextStep as string, formOptions.getState)
    );
  }

  return (
    <div>
      {formOptions.renderForm(currentStep.fields)}
      <FormSpy>
        {() => (
          <div
            className="sticky -bottom-6 z-20 mt-8 border-t border-gray-200 bg-white py-5"
            style={{ backgroundColor: "rgba(256, 256, 256, 0.9)" }}
            data-cy="wizard-buttons"
          >
            <div className="flex justify-end space-x-3">
              {activeStepIndex > 0 ? (
                <button
                  type="button"
                  className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={handlePrevEventHandler}
                  data-cy="btn-back"
                >
                  Back
                </button>
              ) : (
                <button
                  type="button"
                  className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={() => handleCancel()}
                  data-cy="btn-cancel"
                >
                  Cancel
                </button>
              )}
              {currentStep.nextStep && (
                <button
                  type="button"
                  className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={handleNextEventHandler}
                  data-cy="btn-next"
                >
                  Next
                </button>
              )}
              {(!currentStep.nextStep || activityGroupId) && (
                <button
                  type="button"
                  disabled={formOptions.getState().submitting}
                  onClick={() => handleSubmit()}
                  className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  OK
                </button>
              )}
            </div>
          </div>
        )}
      </FormSpy>
    </div>
  );
};

export const AdminBookingItemsWizard = (props: Props) => {
  return (
    <Wizard Wizard={BookingItemsWizard as ComponentType<unknown>} {...props} />
  );
};
