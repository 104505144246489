import { Dialog, Transition } from "@headlessui/react";
import { PlusSmallIcon } from "@heroicons/react/20/solid";
import { CheckIcon, PlusIcon } from "@heroicons/react/24/outline";
import { ActivityFiltersNoDateRange } from "components/admin/ActivityFiltersNoDateRange";
import { SubmitButton } from "components/form/SubmitButton";
import { Loader } from "components/Loader";
import { getActivityTitle, getActivityVenueName, wait } from "helpers/helpers";
import useActivityGroupsFullList from "hooks/useActivityGroupsFullList";
import { uniq } from "lodash";
import { Fragment, useRef, useState } from "react";
import type {
  ActivityGroup,
  ActivityGroupFullListItem
} from "types/model/activity-group";
import { DiscountRuleType } from "types/model/discount-rule";
import type { Field } from "types/model/field";
import type { FieldFilters } from "types/model/field-data";
import type { Venue } from "types/model/venue";

const getHasActivityGroupAlreadyBeenAssignedRuleOfSameType = (
  activityGroup: ActivityGroup | ActivityGroupFullListItem,
  type: DiscountRuleType,
  discountRuleId: string
) => {
  const hasActivityGroupAlreadyBeenAssignedRuleOfSameType =
    [DiscountRuleType.MultiAttendee, DiscountRuleType.MultiPurchase].includes(
      type
    ) &&
    activityGroup.discountRules?.some(discountRule => {
      const typeIsSame = discountRule.type === type;
      const isRuleDifferentToOneBeingEdited =
        discountRule._id !== discountRuleId;
      return typeIsSame && isRuleDifferentToOneBeingEdited;
    });

  return hasActivityGroupAlreadyBeenAssignedRuleOfSameType;
};

export const DiscountRuleActivityGroupsSelectModal = ({
  id,
  venues,
  fields,
  type,
  isOpen,
  selectedActivityGroupIds,
  setSelectedActivityGroupIds,
  resetSelectedActivityGroupIds,
  handleUpdateActivityGroups,
  setIsOpen
}: {
  id?: string;
  venues: Venue[];
  fields: Field[];
  type: DiscountRuleType;
  isOpen: boolean;
  selectedActivityGroupIds: string[];
  setSelectedActivityGroupIds: (activityGroupIds: string[]) => void;
  resetSelectedActivityGroupIds: () => void;
  handleUpdateActivityGroups: (activityGroupIds: string[]) => void;
  setIsOpen: (isOpen: boolean) => void;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [filters, setFilters] = useState<FieldFilters>({});

  const activityGroupsFullListQueryInfo = useActivityGroupsFullList(
    JSON.stringify(filters)
  );

  function handleAddActivityGroup(activityGroupId: string) {
    setSelectedActivityGroupIds([...selectedActivityGroupIds, activityGroupId]);
  }

  function handleRemoveActivityGroup(activityGroupId: string) {
    const updatedActivityGroupIds = selectedActivityGroupIds.filter(
      id => id !== activityGroupId
    );

    setSelectedActivityGroupIds(updatedActivityGroupIds);
  }

  function handleSelectAllActivityGroups(
    activityGroups: ActivityGroupFullListItem[]
  ) {
    const ids: string[] = activityGroups
      .filter(
        activityGroup =>
          !getHasActivityGroupAlreadyBeenAssignedRuleOfSameType(
            activityGroup,
            type,
            id ?? ""
          )
      )
      .map(activityGroup => activityGroup._id);
    setSelectedActivityGroupIds(uniq([...selectedActivityGroupIds, ...ids]));
  }

  function handleUnselectAllActivityGroups(
    activityGroups: ActivityGroupFullListItem[]
  ) {
    const ids: string[] = activityGroups.map(
      activityGroup => activityGroup._id
    );
    const updatedActivityGroupIds = selectedActivityGroupIds.filter(
      id => !ids.includes(id)
    );
    setSelectedActivityGroupIds(updatedActivityGroupIds);
  }

  async function handleSubmit() {
    handleUpdateActivityGroups(selectedActivityGroupIds);
    setIsOpen(false);
    await wait(300);

    setFilters({});
  }

  async function handleClose() {
    setIsOpen(false);
    await wait(300);

    resetSelectedActivityGroupIds();
    setFilters({});
  }

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-10 overflow-y-auto"
        initialFocus={containerRef}
        open={isOpen}
        onClose={handleClose}
      >
        <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block min-w-full transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:min-w-min sm:max-w-lg sm:align-middle md:max-w-2xl">
              <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10">
                    <PlusIcon
                      className="h-6 w-6 text-indigo-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div
                    className="mt-3 w-full text-center sm:ml-4 sm:mt-0 sm:text-left"
                    ref={containerRef}
                  >
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Select activities
                    </Dialog.Title>
                    <ActivityFiltersNoDateRange
                      filterFields={fields}
                      filters={filters}
                      venuesList={venues}
                      visibilityKey="useForAdminFiltering"
                      inModal={true}
                      innerClassName="py-5"
                      handleSetFilters={setFilters}
                    />
                    {activityGroupsFullListQueryInfo.isLoading ? (
                      <Loader />
                    ) : activityGroupsFullListQueryInfo.data ? (
                      <>
                        {activityGroupsFullListQueryInfo.data.length >= 1 ? (
                          <>
                            <div className="text-right text-sm">
                              <a
                                className="cursor-pointer font-medium text-indigo-600 hover:text-indigo-500 focus:underline focus:outline-none"
                                onClick={() =>
                                  handleSelectAllActivityGroups(
                                    activityGroupsFullListQueryInfo.data || []
                                  )
                                }
                              >
                                Select all
                              </a>
                              <span className="text-gray-700">{" | "}</span>
                              <a
                                className="cursor-pointer font-medium text-indigo-600 hover:text-indigo-500 focus:underline focus:outline-none"
                                onClick={() =>
                                  handleUnselectAllActivityGroups(
                                    activityGroupsFullListQueryInfo.data || []
                                  )
                                }
                              >
                                Unselect all
                              </a>
                            </div>
                            <ul
                              role="list"
                              className="mt-2 max-h-bananas divide-y divide-gray-200 overflow-y-scroll border-b border-t border-gray-200 pr-4 sm:max-h-oranges"
                            >
                              {activityGroupsFullListQueryInfo.data.map(
                                activityGroup => (
                                  <li
                                    key={activityGroup._id}
                                    className="flex items-center justify-between space-x-3 py-4 text-left"
                                  >
                                    <div className="flex-grow">
                                      <div className="text-sm font-medium text-gray-700">
                                        {getActivityTitle(activityGroup)}
                                      </div>
                                      <div className="text-sm text-gray-500">
                                        {getActivityVenueName(activityGroup)}
                                      </div>
                                      <div className="text-sm text-gray-500">
                                        {activityGroup.activityGroupStartEnd}
                                      </div>
                                    </div>
                                    <div className="flex-shrink">
                                      {getHasActivityGroupAlreadyBeenAssignedRuleOfSameType(
                                        activityGroup,
                                        type,
                                        id ?? ""
                                      ) ? (
                                        <span className="block text-right text-sm leading-4 text-gray-500">
                                          Another rule already applied
                                        </span>
                                      ) : selectedActivityGroupIds.includes(
                                          activityGroup._id
                                        ) ? (
                                        <button
                                          type="button"
                                          className="inline-flex items-center rounded-full border border-transparent bg-green-50 px-3 py-2 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                                          onClick={() =>
                                            handleRemoveActivityGroup(
                                              activityGroup._id
                                            )
                                          }
                                        >
                                          <CheckIcon
                                            className="-ml-1 mr-0.5 h-5 w-5 text-green-400"
                                            aria-hidden="true"
                                          />
                                          <span className="text-sm font-medium text-green-900">
                                            Added
                                          </span>
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          className="inline-flex items-center rounded-full border border-transparent bg-gray-100 px-3 py-2 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                          onClick={() =>
                                            handleAddActivityGroup(
                                              activityGroup._id
                                            )
                                          }
                                        >
                                          <PlusSmallIcon
                                            className="-ml-1 mr-0.5 h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                          />
                                          <span className="text-sm font-medium text-gray-900">
                                            Add
                                          </span>
                                        </button>
                                      )}
                                    </div>
                                  </li>
                                )
                              )}
                            </ul>
                          </>
                        ) : (
                          <p className="text-sm text-gray-500">
                            No activities found
                          </p>
                        )}
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <SubmitButton
                  buttonType="button"
                  className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={handleSubmit}
                >
                  OK
                </SubmitButton>
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={handleClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
