import type { UseFieldApiConfig } from "@data-driven-forms/react-form-renderer/use-field-api";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import useFormApi from "@data-driven-forms/react-form-renderer/use-form-api";
import { Dialog, Transition } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/20/solid";
import { SubmitButton } from "components/form/SubmitButton";
import { FormError } from "components/FormError";
import useCreateAttendee from "hooks/useCreateAttendee";
import useFields from "hooks/useFields";
import { Fragment, useRef, useState } from "react";
import { FieldUsage } from "types/constants";

export const AttendeeSubForm = (props: UseFieldApiConfig) => {
  const { fields, name } = props;
  const { isOpen } = useFieldApi(props);

  const { renderForm, change, getFieldState, getRegisteredFields } =
    useFormApi();
  const attendeeFieldsQueryInfo = useFields(FieldUsage.Attendee, false, true);
  const [createAttendee, createAttendeeInfo] = useCreateAttendee();

  const [showFormErrorMsg, setShowFormErrorMsg] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const wrapperRef = useRef<any>(null);

  const attendeeIdFieldName = name.replace("newAttendee", "attendeeId");

  const attendeeFields = getRegisteredFields().filter(
    fieldName =>
      !fieldName.includes("lineItemsData") && !fieldName.includes("userId")
  );

  const userField = getRegisteredFields().find(fieldName =>
    fieldName.includes("userId")
  );

  function getAreValuesSetForAllRequiredFields(): boolean {
    const requiredFields =
      attendeeFieldsQueryInfo.data &&
      attendeeFieldsQueryInfo.data.filter(
        field => field.enabled && field.required
      );

    const doesEveryRequiredFieldHaveValue = requiredFields?.every(field => {
      const fieldStateValue = getFieldState(field._id)?.value;

      return Boolean(fieldStateValue);
    });

    return !!doesEveryRequiredFieldHaveValue;
  }

  function handleCancelNewAttendee() {
    change(attendeeIdFieldName, "");

    // Clear attendee fields
    for (const attendeeField of attendeeFields) {
      change(attendeeField, undefined);
    }

    setShowFormErrorMsg(false);
  }

  async function onConfirm() {
    const areValuesSetForAllRequiredFields =
      getAreValuesSetForAllRequiredFields();

    if (!areValuesSetForAllRequiredFields) {
      setShowFormErrorMsg(true);
      return;
    }

    const formData = [...attendeeFields, userField].reduce(
      (acc, fieldId) => {
        acc[fieldId as string] = getFieldState(fieldId as string)?.value;

        return acc;
      },
      {} as Record<string, string>
    );

    const newAttendeeResponse = await createAttendee(formData);
    if (newAttendeeResponse?.status === 201) {
      change(attendeeIdFieldName, newAttendeeResponse.data._id);
    }

    // Clear attendee fields
    for (const attendeeField of attendeeFields) {
      change(attendeeField, undefined);
    }
    setShowFormErrorMsg(false);
  }

  return (
    <div>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 z-10 overflow-y-auto"
          initialFocus={wrapperRef}
          open={isOpen}
          onClose={handleCancelNewAttendee}
        >
          <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block min-w-full transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:min-w-min sm:max-w-lg sm:align-middle md:max-w-2xl lg:max-w-3xl">
                <div
                  className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4"
                  ref={wrapperRef}
                >
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10">
                      <PlusIcon
                        className="h-6 w-6 text-indigo-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 w-full sm:ml-4 sm:mt-0">
                      <Dialog.Title
                        as="h3"
                        className="text-center text-lg font-medium leading-6 text-gray-900 sm:text-left"
                      >
                        Create attendee
                      </Dialog.Title>
                      {createAttendeeInfo.error && (
                        <FormError className="mt-4">
                          {createAttendeeInfo.error.message}
                        </FormError>
                      )}
                      <div className="mb-4 mt-6">
                        {!getFieldState(userField as string)?.value ? (
                          <FormError className="mt-4">No user set</FormError>
                        ) : (
                          <>
                            {renderForm(fields)}
                            {showFormErrorMsg && (
                              <FormError className="mt-4">
                                Sorry, there was a problem submitting the form.
                                Please check all required fields are completed.
                              </FormError>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <SubmitButton
                    buttonType="button"
                    onClick={onConfirm}
                    disabled={createAttendeeInfo.isLoading}
                    isLoading={createAttendeeInfo.isLoading}
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Save
                  </SubmitButton>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={handleCancelNewAttendee}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};
