import type { UseFieldApiConfig } from "@data-driven-forms/react-form-renderer/use-field-api";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import { kebabCase } from "lodash";
import { cn } from "utils/cn";

export const HeaderShowHide = (props: UseFieldApiConfig) => {
  const { label, onLabel, offLabel, input, index } = useFieldApi(props);

  const isFirstItem = index === 0;

  function handleSetValue() {
    input.onChange(!input.value);
  }

  return (
    <div
      className={cn("relative", !isFirstItem && "mt-7")}
      data-cy={`form-field-${kebabCase(label)}`}
    >
      {input.value ? (
        <a
          className="cursor-pointer text-sm font-medium text-indigo-600 hover:text-indigo-900"
          onClick={handleSetValue}
        >
          <span aria-hidden="true">↑</span> {onLabel || label}
        </a>
      ) : (
        <a
          className="cursor-pointer text-sm font-medium text-indigo-600 hover:text-indigo-900"
          onClick={handleSetValue}
        >
          <span aria-hidden="true">↓</span> {offLabel || label}
        </a>
      )}
    </div>
  );
};
