import { isEmpty, kebabCase } from "lodash";
import { useEffect, useState } from "react";
import type { Hash } from "types/general";
import type { Field } from "types/model/field";
import { FieldType } from "types/model/field";
import type { FieldFilters } from "types/model/field-data";
import type { Venue } from "types/model/venue";
import { cn } from "utils/cn";

export const ActivityFiltersNoDateRange = ({
  filterFields,
  filters,
  venuesList,
  inModal = false,
  visibilityKey,
  restrictedFieldOptionsToDisplay = {},
  className,
  innerClassName,
  handleSetFilters
}: {
  filterFields: Field[];
  filters: FieldFilters;
  inModal?: boolean;
  venuesList: Venue[];
  visibilityKey: string;
  restrictedFieldOptionsToDisplay?: Hash<string[]>;
  className?: string;
  innerClassName?: string;
  handleSetFilters: (filters: FieldFilters) => void;
}) => {
  const [filtersKey, setFiltersKey] = useState("");

  const [filtersTemp, setFiltersTemp] = useState<FieldFilters>({});

  function handleSetFiltersTemp(key: string, value: unknown) {
    setFiltersTemp({
      ...filtersTemp,
      [key]: value
    } as FieldFilters);
  }

  function handleApplyFilters() {
    handleSetFilters(filtersTemp);

    const randomKey = Math.random().toString(36).substring(7);
    setFiltersKey(randomKey);
  }

  function handleResetFilters() {
    setFiltersTemp({});
    handleSetFilters({});

    const randomKey = Math.random().toString(36).substring(7);
    setFiltersKey(randomKey);
  }

  useEffect(() => {
    setFiltersTemp(filters);
  }, [filters]);

  return (
    <div className={cn(className, "rounded-lg bg-white", !inModal && "mb-4")}>
      <div className={cn(innerClassName, !inModal && "px-4 sm:p-6")}>
        <div
          className={cn(
            "grid",
            !inModal && "grid-cols-6 gap-6",
            inModal && "grid-cols-4 gap-x-6 gap-y-4"
          )}
        >
          {filterFields
            .filter(field => field[visibilityKey as keyof Field])
            .map(field => {
              if (
                field.type === FieldType.SelectList ||
                field.type === FieldType.Venue
              ) {
                return (
                  <div
                    key={field._id}
                    className="col-span-6 sm:col-span-3 md:col-span-2"
                    data-cy="filter-item"
                  >
                    <label
                      htmlFor={filtersTemp[field._id]}
                      className="block text-left text-sm font-medium text-gray-700"
                    >
                      {field.title}
                    </label>
                    <select
                      id={filtersTemp[field._id]}
                      name={filtersTemp[field._id]}
                      className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      data-cy={`filter-${kebabCase(field.title)}`}
                      key={filtersKey}
                      value={filtersTemp[field._id]}
                      onChange={event =>
                        handleSetFiltersTemp(field._id, event.target.value)
                      }
                    >
                      <option value="">Any</option>
                      {field.type === FieldType.Venue &&
                        venuesList
                          .filter(
                            option =>
                              !restrictedFieldOptionsToDisplay[field._id] ||
                              restrictedFieldOptionsToDisplay[
                                field._id
                              ].includes(option._id)
                          )
                          .map(({ _id, name }) => (
                            <option key={_id} value={_id}>
                              {name}
                            </option>
                          ))}
                      {field.type === FieldType.SelectList &&
                        field.fieldOptions
                          ?.filter(
                            option =>
                              !restrictedFieldOptionsToDisplay[field._id] ||
                              restrictedFieldOptionsToDisplay[
                                field._id
                              ].includes(option._id)
                          )
                          .map(({ _id, name }) => (
                            <option key={_id} value={_id}>
                              {name}
                            </option>
                          ))}
                    </select>
                  </div>
                );
              }
              if (field.type === FieldType.CheckboxSingle) {
                return (
                  <div
                    key={field._id}
                    className="col-span-6 sm:col-span-3 md:col-span-2"
                    data-cy="filter-item"
                  >
                    <label
                      htmlFor={filtersTemp[field._id]}
                      className="block text-left text-sm font-medium text-gray-700"
                    >
                      {field.title}
                    </label>
                    <select
                      id={filtersTemp[field._id]}
                      name={filtersTemp[field._id]}
                      className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      data-cy={`filter-${kebabCase(field.title)}`}
                      key={filtersKey}
                      value={filtersTemp[field._id]}
                      onChange={event =>
                        handleSetFiltersTemp(field._id, event.target.value)
                      }
                    >
                      <option value="">Any</option>
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  </div>
                );
              }
              if (field.type === FieldType.Text) {
                return (
                  <div
                    key={field._id}
                    className="col-span-6 sm:col-span-3 md:col-span-2"
                    data-cy="filter-item"
                  >
                    <label
                      htmlFor={filtersTemp[field._id]}
                      className="block text-left text-sm font-medium text-gray-700"
                    >
                      {field.title}
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        id={filtersTemp[field._id]}
                        name={filtersTemp[field._id]}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        placeholder={`Filter by ${field.title}`}
                        data-cy={`filter-${kebabCase(field.title)}`}
                        key={filtersKey}
                        value={filtersTemp[field._id]}
                        onChange={event =>
                          handleSetFiltersTemp(field._id, event.target.value)
                        }
                      />
                    </div>
                  </div>
                );
              }
            })}
        </div>
        <div className="mt-6 flex gap-3">
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-100 px-4 py-2 font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
            onClick={handleApplyFilters}
          >
            Apply filters
          </button>
          {!isEmpty(filters) && (
            <button
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
              onClick={handleResetFilters}
            >
              Reset
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
