import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";

const StatusItem = ({
  enabled,
  textEnabled,
  textDisabled
}: {
  enabled: boolean;
  textEnabled: string;
  textDisabled: string;
}) => (
  <span className="flex items-center">
    <span className="relative flex h-6 w-6 flex-shrink-0 items-center justify-center">
      {enabled ? (
        <CheckCircleIcon className="h-5 w-5 text-green-600" />
      ) : (
        <XCircleIcon className="h-5 w-5 text-gray-400" />
      )}
    </span>
    <span className="ml-1.5 text-sm text-gray-600">
      {enabled ? textEnabled : textDisabled}
    </span>
  </span>
);

export const StripeConnectedAccountStatusInfo = ({
  detailsSubmitted,
  chargesEnabled,
  payoutsEnabled
}: {
  detailsSubmitted: boolean;
  chargesEnabled: boolean;
  payoutsEnabled: boolean;
}) => {
  const statusItems = [
    {
      enabled: detailsSubmitted,
      textEnabled: "Details submitted",
      textDisabled: "Details not submitted"
    },
    {
      enabled: chargesEnabled,
      textEnabled: "Charges enabled",
      textDisabled: "Charges not enabled"
    },
    {
      enabled: payoutsEnabled,
      textEnabled: "Payouts enabled",
      textDisabled: "Payouts not enabled"
    }
  ];

  return (
    <div className="mt-2 space-y-1 text-sm text-gray-600">
      {statusItems.map((item, index) => (
        <StatusItem
          key={index}
          enabled={item.enabled}
          textEnabled={item.textEnabled}
          textDisabled={item.textDisabled}
        />
      ))}
    </div>
  );
};
