import { Dialog, Transition } from "@headlessui/react";
import { UserGroupIcon } from "@heroicons/react/20/solid";
import { SubmitButton } from "components/form/SubmitButton";
import { NewAttendeeForm } from "components/site/NewAttendeeForm";
import { wait } from "helpers/helpers";
import useCreateAttendee from "hooks/useCreateAttendee";
import { Fragment, useRef } from "react";
import type { FormDataItem } from "types/form";
import type { Client } from "types/model/client";
import type { Field } from "types/model/field";

export const AdminBookingItemsAddAttendeeModal = ({
  userId,
  activityGroupId,
  itemId,
  index,
  attendeeFields,
  client,
  isOpen,
  handleSelectAttendee,
  setIsOpen
}: {
  userId: string;
  activityGroupId: string;
  itemId: string;
  index: number;
  attendeeFields: Field[];
  client: Client;
  isOpen: boolean;
  handleSelectAttendee: (
    attendeeId: string,
    activityGroupId: string,
    itemId: string,
    index: number
  ) => void;
  setIsOpen: (isOpen: boolean) => void;
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [createAttendee, createAttendeeInfo] = useCreateAttendee();

  // const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();
  //   const submitBtn: HTMLButtonElement = document.querySelector(
  //     '#attendeeForm #form-submit-button'
  //   );
  //   if (submitBtn) {
  //     submitBtn.click();
  //   }
  // };

  async function handleSubmit(formData: FormDataItem[]) {
    const data = {
      ...formData,
      userId
    } as FormDataItem;

    const newAttendeeResponse = await createAttendee(data);

    if (newAttendeeResponse?.status === 201) {
      await wait(300); //  Ensure query cash is updated
      handleSelectAttendee(
        newAttendeeResponse.data._id,
        activityGroupId,
        itemId,
        index
      );
      setIsOpen(false);
    }
  }

  function handleClose() {
    setIsOpen(false);
  }

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-10 overflow-y-auto"
        initialFocus={wrapperRef}
        open={isOpen}
        onClose={handleClose}
      >
        <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block min-w-full transform overflow-x-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:min-w-min sm:max-w-lg sm:align-middle md:max-w-2xl lg:max-w-3xl">
              <div
                className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4"
                ref={wrapperRef}
              >
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10">
                    <UserGroupIcon
                      className="h-6 w-6 text-indigo-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 w-full sm:ml-4 sm:mt-0">
                    <Dialog.Title
                      as="h3"
                      className="text-center text-lg font-medium leading-6 text-gray-900 sm:text-left"
                    >
                      Add attendee
                    </Dialog.Title>
                    <div className="mb-4 mt-6">
                      <NewAttendeeForm
                        lineItemId={"NONE"}
                        attendeeFields={attendeeFields}
                        client={client}
                        isInModal={true}
                        activityGroupId={activityGroupId}
                        handleSubmit={handleSubmit}
                        onCancel={handleClose}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <SubmitButton
                  buttonType="submit"
                  form="attendeeForm"
                  disabled={createAttendeeInfo.isLoading}
                  isLoading={createAttendeeInfo.isLoading}
                  className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Submit
                </SubmitButton>
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={handleClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
