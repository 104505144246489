import type {
  UseFieldApiConfig,
  UseFieldApiProps
} from "@data-driven-forms/react-form-renderer/use-field-api";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import { Switch } from "@headlessui/react";
import { HelpText } from "components/form/HelpText";
import { kebabCase } from "lodash";
import type { ChangeEvent } from "react";
import { FormTemplateType } from "types/form";
import type { DiscountRuleType } from "types/model/discount-rule";
import { cn } from "utils/cn";

export interface DiscountRuleRedemptionLimitsValue {
  shouldLimitRedemptions: boolean;
  shouldLimitUserRedemptions: boolean;
  maxRedemptions: string;
  maxUserRedemptions: string;
  inModal?: boolean;
  formTemplate?: FormTemplateType;
  discountRuleType?: DiscountRuleType;
}

export const DiscountRuleRedemptionLimits = (props: UseFieldApiConfig) => {
  const {
    label,
    input,
    isRequired,
    meta: { error, touched },
    index,
    helpText,
    arrayField,
    inModal,
    formTemplate = FormTemplateType.Default,
    isSubFormSubmitted
  }: UseFieldApiProps<
    DiscountRuleRedemptionLimitsValue,
    HTMLElement
  > = useFieldApi(props);

  const isDefaultFormTemplate = formTemplate === FormTemplateType.Default;
  const isSeamlessFormTemplate = formTemplate === FormTemplateType.Seamless;
  const isFirstItem = index === 0;

  const touchedOrSubFormSubmitted = touched || isSubFormSubmitted;

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;

    input.onChange({
      ...input.value,
      [e.target.name]: value
    });
  }

  return (
    <div
      className={cn(
        !inModal &&
          isDefaultFormTemplate &&
          "sm:grid sm:grid-cols-3 sm:items-start sm:gap-4",
        isDefaultFormTemplate &&
          !arrayField &&
          !isFirstItem &&
          "mt-5 border-t border-gray-200 pt-5",
        (isSeamlessFormTemplate || arrayField) && !isFirstItem && "mt-5"
      )}
    >
      <label
        className="hidden text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:block sm:pt-2"
        htmlFor={input.name}
      >
        {label}
        {isRequired && "*"}
      </label>
      <div
        className={cn(
          "space-y-4",
          isSeamlessFormTemplate && "mt-2",
          isDefaultFormTemplate && "mt-1 sm:col-span-2"
        )}
      >
        <div>
          <Switch.Group as="div" className="flex items-center">
            <Switch
              checked={input.value.shouldLimitUserRedemptions}
              name="shouldLimitUserRedemptions"
              onChange={(value: boolean) =>
                handleChange({
                  target: {
                    checked: value,
                    name: "shouldLimitUserRedemptions",
                    type: "checkbox"
                  }
                } as ChangeEvent<HTMLInputElement>)
              }
              className={cn(
                input.value.shouldLimitUserRedemptions
                  ? "bg-indigo-600"
                  : "bg-gray-200",
                "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              )}
            >
              <span
                aria-hidden="true"
                className={cn(
                  input.value.shouldLimitUserRedemptions
                    ? "translate-x-5"
                    : "translate-x-0",
                  "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                )}
              />
            </Switch>
            <Switch.Label as="span" className="ml-3">
              <span className="text-sm font-medium text-gray-900">
                Limit the number of times this discount code can be redeemed by
                a user
              </span>
            </Switch.Label>
          </Switch.Group>
          {input.value.shouldLimitUserRedemptions && (
            <div className="relative mb-8 mt-5 sm:max-w-sm">
              <input
                name="maxUserRedemptions"
                type="number"
                className="block w-full rounded-md border-gray-300 pr-14 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                value={input.value.maxUserRedemptions}
                onChange={handleChange}
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <span className="text-gray-500 sm:text-sm sm:leading-5">
                  times
                </span>
              </div>
            </div>
          )}
        </div>
        <div>
          <Switch.Group as="div" className="flex items-center">
            <Switch
              checked={input.value.shouldLimitRedemptions}
              name="shouldLimitRedemptions"
              onChange={(value: boolean) =>
                handleChange({
                  target: {
                    checked: value,
                    name: "shouldLimitRedemptions",
                    type: "checkbox"
                  }
                } as ChangeEvent<HTMLInputElement>)
              }
              className={cn(
                input.value.shouldLimitRedemptions
                  ? "bg-indigo-600"
                  : "bg-gray-200",
                "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              )}
            >
              <span
                aria-hidden="true"
                className={cn(
                  input.value.shouldLimitRedemptions
                    ? "translate-x-5"
                    : "translate-x-0",
                  "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                )}
              />
            </Switch>
            <Switch.Label as="span" className="ml-3">
              <span className="text-sm font-medium text-gray-900">
                Limit the total number of times this discount code can be
                redeemed
              </span>
            </Switch.Label>
          </Switch.Group>
          {input.value.shouldLimitRedemptions && (
            <div className="relative mt-5 sm:max-w-sm">
              <input
                name="maxRedemptions"
                type="number"
                className="block w-full rounded-md border-gray-300 pr-14 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                value={input.value.maxRedemptions}
                onChange={handleChange}
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <span className="text-gray-500 sm:text-sm sm:leading-5">
                  times
                </span>
              </div>
            </div>
          )}{" "}
        </div>
      </div>
      {helpText && <HelpText label={label} helpText={helpText} />}
      {touchedOrSubFormSubmitted && error && (
        <p
          className="mt-2 text-sm text-red-600"
          data-cy={`error-${kebabCase(label)}`}
        >
          {error}
        </p>
      )}
    </div>
  );
};
