import type { UseFieldApiConfig } from "@data-driven-forms/react-form-renderer/use-field-api";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import { Switch } from "@headlessui/react";
import { HelpText } from "components/form/HelpText";
import { kebabCase } from "lodash";
import { FormTemplateType } from "types/form";
import { cn } from "utils/cn";

export const Toggle = (props: UseFieldApiConfig) => {
  const {
    label,
    switchLabel,
    input,
    isRequired,
    isDisabled,
    meta: { error, touched },
    index,
    helpText,
    inModal,
    arrayField,
    formTemplate = FormTemplateType.Default
  } = useFieldApi(props);

  const isSeamlessFormTemplate = formTemplate === FormTemplateType.Seamless;
  const isFirstItem = index === 0;

  return (
    <>
      {isSeamlessFormTemplate ? (
        <div
          className={cn("relative", !isFirstItem && "mt-7")}
          data-cy={`form-field-${kebabCase(label)}`}
        >
          {/* note: dropped this in but not used or tested on seamless template yet */}
          <Switch.Group as="div" className="flex items-center">
            <Switch
              checked={input.value}
              disabled={isDisabled}
              onChange={input.onChange}
              className={cn(
                input.value ? "bg-indigo-600" : "bg-gray-200",
                "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              )}
            >
              <span
                aria-hidden="true"
                className={cn(
                  input.value ? "translate-x-5" : "translate-x-0",
                  "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                )}
              />
            </Switch>
            <Switch.Label as="span" className="ml-3 leading-4">
              <span className="text-sm font-medium text-gray-900">
                {switchLabel || label}
              </span>
            </Switch.Label>
          </Switch.Group>
          {helpText && <HelpText label={label} helpText={helpText} />}
          {touched && error && (
            <p className="mt-2 text-sm text-red-600">{error}</p>
          )}
        </div>
      ) : (
        <div
          className={cn(
            !inModal && "sm:grid sm:grid-cols-3 sm:items-start sm:gap-4",
            !arrayField && index !== 0 && "mt-5 border-t border-gray-200 pt-5",
            arrayField && index !== 0 && "mt-5"
          )}
          data-cy={`form-field-${kebabCase(label)}`}
        >
          <label
            className="hidden text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:block sm:pt-2"
            htmlFor={input.name}
          >
            {label}
            {isRequired && "*"}
          </label>
          <div className="relative mt-1 sm:col-span-2 sm:max-w-sm">
            <Switch.Group as="div" className="flex items-center">
              <Switch
                checked={input.value}
                disabled={isDisabled}
                onChange={input.onChange}
                className={cn(
                  input.value ? "bg-indigo-600" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                )}
                data-cy={`btn-switch-${kebabCase(switchLabel || label)}`}
              >
                <span
                  aria-hidden="true"
                  className={cn(
                    input.value ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
              <Switch.Label as="span" className="ml-3 leading-4">
                <span className="text-sm font-medium text-gray-900">
                  {switchLabel || label}
                </span>
              </Switch.Label>
            </Switch.Group>
            {helpText && <HelpText label={label} helpText={helpText} />}
            {touched && error && (
              <p className="mt-2 text-sm text-red-600">{error}</p>
            )}
          </div>
        </div>
      )}
    </>
  );
};
