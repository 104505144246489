import useFormApi from "@data-driven-forms/react-form-renderer/use-form-api";
import { Dialog, Transition } from "@headlessui/react";
import { SubmitButton } from "components/form/SubmitButton";
import { FormError } from "components/FormError";
import {
  getDiscountFormDataForSubmission,
  getDiscountRuleTypeReadableName
} from "helpers/discount";
import { generateDiscountRuleFormSchema } from "helpers/form/discounts";
import { wait } from "helpers/helpers";
import useCreateDiscountRule from "hooks/useCreateDiscountRule";
import { isEmpty } from "lodash";
import { Fragment, useRef, useState } from "react";
import { FormTemplateType } from "types/form";
import type { Client } from "types/model/client";
import type { DiscountRuleType } from "types/model/discount-rule";

export const CreateDiscountRuleModal = ({
  isOpen,
  discountRuleType,
  client,
  setDiscountRuleFieldValue,
  setIsOpen
}: {
  isOpen: boolean;
  discountRuleType: DiscountRuleType;
  client: Client;
  setDiscountRuleFieldValue?: (value: string) => void;
  setIsOpen: (isOpen: boolean) => void;
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [isSubmitted, setIsSubmitted] = useState(false);

  const [createDiscountRule, createDiscountRuleInfo] = useCreateDiscountRule();

  const fieldSchemaPrefix = "discountRuleSubForm";

  const formSchema = generateDiscountRuleFormSchema({
    client,
    includeActivityGroupSelect: false,
    formTemplate: FormTemplateType.Seamless,
    prefix: fieldSchemaPrefix,
    inModal: true,
    type: discountRuleType
  });

  const { renderForm, getState, change } = useFormApi();

  async function handleSubmit() {
    setIsSubmitted(true);

    const formState = getState();
    const formData = formState.values[fieldSchemaPrefix];
    const formErrors = formState.errors?.[fieldSchemaPrefix];

    const hasErrors = !isEmpty(formErrors);

    if (hasErrors) return;

    const formDataToSubmit = getDiscountFormDataForSubmission({
      formData,
      formSchema,
      prefix: fieldSchemaPrefix,
      inModal: true,
      client
    });

    const response = await createDiscountRule(formDataToSubmit);

    if (response?.status === 201) {
      setDiscountRuleFieldValue?.(response.data._id);
      setIsOpen(false);
      await wait(300); // Wait for modal to transition to not open before resetting state stuff.

      change(fieldSchemaPrefix, undefined);
    } else {
      wrapperRef.current?.scrollIntoView();
    }

    setIsSubmitted(false);
  }

  async function handleClose() {
    setIsOpen(false);
    await wait(300); // Wait for modal to transition to not open before resetting state stuff.

    createDiscountRuleInfo.reset();
    change(fieldSchemaPrefix, undefined);
    setIsSubmitted(false);
  }

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-10 overflow-y-auto"
        initialFocus={wrapperRef}
        open={isOpen}
        onClose={handleClose}
      >
        <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block min-w-full transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:min-w-min sm:max-w-lg sm:align-middle md:max-w-2xl">
              <div
                className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4"
                ref={wrapperRef}
              >
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10">
                    <svg
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 text-indigo-600"
                      fill="currentColor"
                    >
                      <path d="M22.41,10.59,20.36,8.54V5.63a2,2,0,0,0-2-2H15.46l-2.05-2a2,2,0,0,0-2.82,0L8.54,3.64H5.63a2,2,0,0,0-2,2V8.54l-2,2.05A2,2,0,0,0,1,12a2,2,0,0,0,.58,1.41l2.06,2.05v2.91a2,2,0,0,0,2,2H8.54l2.05,2.05A2,2,0,0,0,12,23a2,2,0,0,0,1.41-.58l2.05-2.06h2.91a2,2,0,0,0,2-2V15.46l2.05-2.05a2,2,0,0,0,0-2.82Zm-4.05,4.05v3.72H14.64L12,21,9.36,18.36H5.64V14.64L3,12,5.64,9.36V5.64H9.36L12,3l2.64,2.64h3.72V9.36L21,12Z" />
                      <path d="M14.24,7.85l-6,7a1,1,0,0,0,1.52,1.3l6-7a1,1,0,0,0-1.52-1.3Z" />
                      <path d="M10.9,10.45a1.5,1.5,0,1,0-1.85-1A1.51,1.51,0,0,0,10.9,10.45Z" />
                      <path d="M13.1,13.55a1.5,1.5,0,1,0,1.85,1A1.51,1.51,0,0,0,13.1,13.55Z" />
                    </svg>
                  </div>
                  <div className="mt-3 w-full sm:ml-4 sm:mt-0">
                    <Dialog.Title
                      as="h3"
                      className="text-center text-lg font-medium leading-6 text-gray-900 sm:text-left"
                    >
                      Create {getDiscountRuleTypeReadableName(discountRuleType)}
                    </Dialog.Title>
                    {createDiscountRuleInfo.error && (
                      <FormError className="mt-4">
                        {createDiscountRuleInfo.error.message}
                      </FormError>
                    )}
                    <div className="mb-4 mt-6">
                      {renderForm(
                        formSchema.fields.map(field => ({
                          ...field,
                          isSubFormSubmitted: isSubmitted
                        }))
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <SubmitButton
                  buttonType="button"
                  form="createDiscountRuleForm"
                  disabled={createDiscountRuleInfo.isLoading}
                  isLoading={createDiscountRuleInfo.isLoading}
                  className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={handleSubmit}
                >
                  Submit
                </SubmitButton>
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={handleClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
