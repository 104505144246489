import type {
  UseFieldApiConfig,
  UseFieldApiProps
} from "@data-driven-forms/react-form-renderer/use-field-api";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import {
  ExclamationCircleIcon,
  ExclamationTriangleIcon
} from "@heroicons/react/20/solid";
import { HelpText } from "components/form/HelpText";
import { capitalize } from "helpers/helpers";
import { kebabCase } from "lodash";
import type { ChangeEvent } from "react";
import { DateDuration } from "types/date";
import { FormTemplateType } from "types/form";
import { cn } from "utils/cn";

export interface DaveValidityValue {
  amount?: number;
  unit?: DateDuration;
  inModal?: boolean;
  formTemplate?: FormTemplateType;
}

export const DateValidity = (props: UseFieldApiConfig) => {
  const {
    label,
    input,
    isRequired,
    isDisabled,
    meta: { error, warning, touched },
    unitOptions = [DateDuration.Days, DateDuration.Months, DateDuration.Years],
    index,
    helpText,
    arrayField,
    inModal,
    formTemplate = FormTemplateType.Default,
    isSubFormSubmitted
  }: UseFieldApiProps<DaveValidityValue, HTMLElement> = useFieldApi(props);

  const isDefaultFormTemplate = formTemplate === FormTemplateType.Default;
  const isSeamlessFormTemplate = formTemplate === FormTemplateType.Seamless;
  const isFirstItem = index === 0;

  const touchedOrSubFormSubmitted = touched || isSubFormSubmitted;

  function handleChange(e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    const value = e.target.value;

    input.onChange({
      ...input.value,
      [e.target.name]: value
    });
  }

  return (
    <div
      className={cn(
        !inModal &&
          isDefaultFormTemplate &&
          "sm:grid sm:grid-cols-3 sm:items-start sm:gap-4",
        isDefaultFormTemplate &&
          !arrayField &&
          !isFirstItem &&
          "mt-5 border-t border-gray-200 pt-5",
        (isSeamlessFormTemplate || arrayField) && !isFirstItem && "mt-5"
      )}
    >
      <label
        className="hidden text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:block sm:pt-2"
        htmlFor={input.name}
      >
        {label}
        {isRequired && "*"}
      </label>
      <div
        className={cn(
          "mt-1",
          isDefaultFormTemplate && "sm:col-span-2 sm:max-w-sm"
        )}
      >
        <div className="relative">
          <input
            type="number"
            name="amount"
            id="amount"
            className={cn(
              "block w-full rounded-md border-gray-300 pr-24 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm",
              touchedOrSubFormSubmitted &&
                error &&
                "focus:shadow-outline-red border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-300"
            )}
            value={input.value.amount || ""}
            disabled={isDisabled}
            data-cy={`input-${kebabCase(label)}`}
            onChange={handleChange}
          />
          <div className="absolute inset-y-0 right-0 flex items-center">
            <label htmlFor="unit" className="sr-only">
              Unit
            </label>
            <select
              id="unit"
              name="unit"
              className="h-full rounded-md border-transparent bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              value={input.value.unit}
              onChange={handleChange}
            >
              {unitOptions.map((unit: DateDuration) => (
                <option key={unit} value={unit}>
                  {capitalize(unit)}
                </option>
              ))}
            </select>
          </div>
          {touchedOrSubFormSubmitted && error && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <ExclamationCircleIcon
                className="h-5 w-5 text-red-500"
                aria-hidden="true"
                data-cy={`icon-exclamation-${kebabCase(label)}`}
              />
            </div>
          )}
          {touchedOrSubFormSubmitted && warning && !error && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <ExclamationTriangleIcon
                className="h-5 w-5 text-yellow-400"
                aria-hidden="true"
                data-cy={`icon-exclamation-warning-${kebabCase(label)}`}
              />
            </div>
          )}
        </div>
        {helpText && <HelpText label={label} helpText={helpText} />}
        {touchedOrSubFormSubmitted && error && (
          <p
            className="mt-2 text-sm text-red-600"
            data-cy={`error-${kebabCase(label)}`}
          >
            {error}
          </p>
        )}
        {touchedOrSubFormSubmitted && warning && (
          <p
            className="mt-2 text-sm text-yellow-700"
            data-cy={`warning-${kebabCase(label)}`}
          >
            {warning}
          </p>
        )}
      </div>
    </div>
  );
};
