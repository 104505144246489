import type { UseFieldApiConfig } from "@data-driven-forms/react-form-renderer/use-field-api";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import { HelpText } from "components/form/HelpText";
import { kebabCase } from "lodash";
import IconExclamationCircleSolid from "public/images/icons/exclamation-circle_solid.svg";
import type { ChangeEvent } from "react";
import { useCallback, useMemo } from "react";
import type { Duration } from "types/form";
import { FormTemplateType } from "types/form";
import { cn } from "utils/cn";

type Props = UseFieldApiConfig & {
  label: string;
  isRequired: boolean;
  helpText?: string;
  arrayField?: boolean;
  inModal?: boolean;
  formTemplate?: FormTemplateType;
  isSubFormSubmitted?: boolean;
  hideLabel?: boolean;
  disabled?: boolean;
};

export const DurationField = (props: Props) => {
  const {
    label,
    input,
    isRequired,
    meta: { error, touched },
    index,
    helpText,
    arrayField,
    inModal,
    formTemplate = FormTemplateType.Default,
    isSubFormSubmitted,
    hideLabel = false,
    disabled = false
  } = useFieldApi(props);

  const isDefaultFormTemplate = formTemplate === FormTemplateType.Default;
  const isSeamlessFormTemplate = formTemplate === FormTemplateType.Seamless;
  const isFirstItem = index === 0;

  const touchedOrSubFormSubmitted = touched || isSubFormSubmitted;

  // Ensure input.value is always an object with hours and minutes
  const inputValue: Duration = useMemo(
    () => input.value || { hours: "", minutes: "" },
    [input.value]
  );

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value, name } = event.target;
      const fieldName = name.replace(`${input.name}_`, "") as keyof Duration;

      input.onChange({
        ...inputValue,
        [fieldName]: String(Number(value))
      });
    },
    [input, inputValue]
  );

  const renderInput = useCallback(
    (fieldName: keyof Duration, label: string, min: number, max: number) => (
      <div>
        <label
          htmlFor={`${input.name}_${fieldName}`}
          className="mt-1 block text-sm font-medium text-gray-700 sm:mt-0"
        >
          {label}
        </label>
        <div className="relative">
          <input
            id={`${input.name}_${fieldName}`}
            name={`${input.name}_${fieldName}`}
            type="number"
            min={min}
            max={max}
            className={cn(
              "mt-1 block min-w-24 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm",
              touchedOrSubFormSubmitted &&
                error &&
                "focus:shadow-outline-red border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-300"
            )}
            disabled={disabled}
            data-cy={`input-${kebabCase(label)}_${fieldName}`}
            value={inputValue[fieldName]}
            onChange={handleChange}
          />
          {touchedOrSubFormSubmitted && error && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <IconExclamationCircleSolid
                width={20}
                height={20}
                className="h-5 w-5 text-red-500"
                data-cy={`icon-exclamation-${kebabCase(label)}_${fieldName}`}
              />
            </div>
          )}
        </div>
      </div>
    ),
    [
      input.name,
      touchedOrSubFormSubmitted,
      error,
      disabled,
      inputValue,
      handleChange
    ]
  );

  return (
    <div
      className={cn(
        !inModal &&
          isDefaultFormTemplate &&
          "sm:grid sm:grid-cols-3 sm:items-start sm:gap-4",
        isDefaultFormTemplate &&
          !arrayField &&
          !isFirstItem &&
          "mt-5 border-t border-gray-200 pt-5",
        (isSeamlessFormTemplate || arrayField) && !isFirstItem && "mt-5"
      )}
    >
      {!hideLabel && (
        <label
          className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
          htmlFor={input.name}
        >
          {label}
          {isRequired && "*"}
        </label>
      )}
      <div
        className={cn(
          "mt-1",
          isDefaultFormTemplate && "sm:col-span-2 sm:max-w-sm"
        )}
      >
        <div className="flex gap-4">
          {renderInput("hours", "Hours", 0, 99)}
          {renderInput("minutes", "Minutes", 0, 59)}
        </div>
        {helpText && <HelpText label={label} helpText={helpText} />}
        {touchedOrSubFormSubmitted && error && (
          <p
            className="mt-2 text-sm text-red-600"
            data-cy={`error-${kebabCase(label)}`}
          >
            {error}
          </p>
        )}
      </div>
    </div>
  );
};
