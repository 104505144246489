import type { UseFieldApiConfig } from "@data-driven-forms/react-form-renderer/use-field-api";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import { HelpText } from "components/form/HelpText";
import { kebabCase } from "lodash";
import IconExclamationCircleSolid from "public/images/icons/exclamation-circle_solid.svg";
import type { ChangeEvent } from "react";
import "react-calendar/dist/Calendar.css";
import "react-date-picker/dist/DatePicker.css";
import { FormTemplateType } from "types/form";
import { cn } from "utils/cn";

export const DateAndTime = (props: UseFieldApiConfig) => {
  const {
    label,
    input,
    isRequired,
    meta: { error, touched },
    index,
    helpText,
    arrayField,
    inModal,
    formTemplate = FormTemplateType.Default,
    isSubFormSubmitted,
    hideLabel = false
  } = useFieldApi(props);

  const isDefaultFormTemplate = formTemplate === FormTemplateType.Default;
  const isSeamlessFormTemplate = formTemplate === FormTemplateType.Seamless;
  const isFirstItem = index === 0;

  const touchedOrSubFormSubmitted = touched || isSubFormSubmitted;

  function handleChange(e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    const value = e.target.value;
    const name = e.target.name.replace(`${input.name}_`, "");

    input.onChange({
      ...input.value,
      [name]: value
    });
  }

  return (
    <div
      className={cn(
        !inModal &&
          isDefaultFormTemplate &&
          "sm:grid sm:grid-cols-3 sm:items-start sm:gap-4",
        isDefaultFormTemplate &&
          !arrayField &&
          !isFirstItem &&
          "mt-5 border-t border-gray-200 pt-5",
        (isSeamlessFormTemplate || arrayField) && !isFirstItem && "mt-5"
      )}
    >
      {!hideLabel && (
        <label
          className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
          htmlFor={input.name}
        >
          {label}
          {isRequired && "*"}
        </label>
      )}
      <div
        className={cn(
          "mt-1",
          isDefaultFormTemplate && "sm:col-span-2 sm:max-w-sm"
        )}
      >
        <div className="relative">
          <input
            id={`${input.name}_date`}
            name={`${input.name}_date`}
            type="date"
            required
            className={cn(
              "block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm",
              touchedOrSubFormSubmitted &&
                error &&
                "focus:shadow-outline-red border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-300"
            )}
            data-cy={`input-${kebabCase(label)}_date`}
            value={input.value.date}
            onChange={handleChange}
          />
          {touchedOrSubFormSubmitted && error && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <IconExclamationCircleSolid
                width={20}
                height={20}
                className="h-5 w-5 text-red-500"
                data-cy={`icon-exclamation-${kebabCase(label)}_date`}
              />
            </div>
          )}
        </div>
        <div className="relative mt-2">
          <input
            id={`${input.name}_time`}
            name={`${input.name}_time`}
            type="time"
            required
            className={cn(
              "block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm",
              touchedOrSubFormSubmitted &&
                error &&
                "focus:shadow-outline-red border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-300"
            )}
            data-cy={`input-${kebabCase(label)}_time`}
            value={input.value.time}
            onChange={handleChange}
          />
          {touchedOrSubFormSubmitted && error && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <IconExclamationCircleSolid
                width={20}
                height={20}
                className="h-5 w-5 text-red-500"
                data-cy={`icon-exclamation-${kebabCase(label)}_time`}
              />
            </div>
          )}
        </div>
        {helpText && <HelpText label={label} helpText={helpText} />}
        {touchedOrSubFormSubmitted && error && (
          <p
            className="mt-2 text-sm text-red-600"
            data-cy={`error-${kebabCase(label)}`}
          >
            {error}
          </p>
        )}
      </div>
    </div>
  );
};
